import { ChangeEvent, useState } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../styles';
import { Typography } from '../common/Typography';
import { getSplittedCost } from '../damage/DamageInfo';

type Props = {
  placeholder: string;
  hint?: string;
  mb?: number;
  isValid?: boolean;
  setValid?: any;
  isMobile?: boolean;
};

const {
  text: { hintGray, orangeError },
  border: { accidentCard, orange },
} = theme.colors;

const StyledComponent = styled.div<Props>`
  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb}px;
    `};
  max-width: 363px;
  height: 96px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid ${accidentCard};
  border-radius: 16px;
  border: 1px solid ${({ isValid }) => (isValid ? accidentCard : orange)};
  padding: 12px 16px;
  ${theme.breakpoints.mobile} {
    padding: 8px 16px;
    height: unset;
  }
  input {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    color: #ffffff;
    font-size: 30px;
    line-height: 35px;
    font-family: 'SB Sans Interface';
    ${theme.breakpoints.mobile} {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
    }
    &:placeholder {
      font-weight: 600;
      font-size: 21px;
      line-height: 32px;
    }
  }
`;

const isOnlyNumbers = (value: string) =>
  !Number.isNaN(value) && value[0] !== '';

export const AccidentInput = (props: Props) => {
  const { hint, placeholder, setValid, isValid, isMobile } = props;

  const [value, setValue] = useState('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    const withoutSpaces = v
      .split('')
      .filter((_, i) => v.charCodeAt(i) !== 160)
      .join('');

    if ((isOnlyNumbers(v) && +withoutSpaces < 9999999999) || v === '') {
      const result = getSplittedCost(+withoutSpaces);
      setValue(result !== '0' ? result : '');
      setValid(v.length);
    }
  };

  return (
    <StyledComponent {...props}>
      {hint && (
        <Typography
          m={isMobile ? '0 0 2px' : '0 0 6px'}
          fz={isMobile ? 18 : 20}
          lh={isMobile ? 24 : 28}
          ls={isMobile ? undefined : '-0.03em'}
          fw={400}
          color={isValid ? hintGray : orangeError}
          ff="SB Sans Interface"
        >
          {isValid ? hint : 'Введите сумму'}
        </Typography>
      )}
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
      />
    </StyledComponent>
  );
};
