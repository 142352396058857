import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

import { theme } from '../styles';
import { useMobile } from '../hooks';
import { requestCall } from '../services';
import { getUserId } from '../services/userService';

import { PageWrapper } from '../components/layout/PageWrapper';
import { StandMobile } from '../components/common/StandMobile';
import { Typography } from '../components/common/Typography';

import { Button } from '../components/buttons/Button';
import { BackBtn } from '../components/buttons/BackBtn';

const {
  text: { asphalt, lightGreen, orangeError },
  background: { gray, darkGreen },
  border: { lightGray },
} = theme.colors;

const PhoneInput = styled.div`
  width: 100%;
  text-align: center;

  & > input {
    width: 100%;
    border: 2px solid ${lightGray};
    border-radius: 16px;
    background: ${gray};
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    padding: 18px 20px;
    overflow: hidden;
    margin-bottom: 8px;
    min-height: 64px;
    font-family: 'SB Sans Interface';
    color: ${asphalt};
    max-width: 338px;
    &::placeholder {
      color: ${asphalt};
    }
    &.error {
      color: ${orangeError};
      border: 2px solid ${orangeError};
      &::placeholder {
        color: ${orangeError};
      }
    }
    ${theme.breakpoints.mobile} {
      max-width: 344px;
    }
  }
`;

const TextArea = styled.textarea`
  border: 2px solid ${lightGray};
  border-radius: 16px;
  width: 100%;
  background: ${gray};
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding: 20px;
  margin-bottom: 40px;
  min-height: 130px;
  font-family: 'SB Sans Interface';
  color: ${asphalt};
  &&::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  &::placeholder {
    color: ${asphalt};
  }
  &.error {
    color: ${orangeError};
    border: 2px solid ${orangeError};
    &::placeholder {
      color: ${orangeError};
    }
  }
  max-width: 338px;
  ${theme.breakpoints.mobile} {
    max-width: 344px;
    margin-bottom: 94px;
  }
`;

export const RequestCallPage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();
const user = getUserId();

  const [isQuestionValid, setQuestionValid] = useState(false);
  const [isPhoneValid, setPhoneValid] = useState(false);
  const [isTouched, setTouched] = useState(false);
  const [phone, setPhone] = useState('');
  const [question, setQuestion] = useState('');

  const handleClick = () => {
    if (isPhoneValid && isQuestionValid && phone.length && question.length) {
      requestCall({
        user,
        phone: phone.slice(3).replaceAll(/[)-]/g, ''),
        description: question,
      })
        .then(() => navigate('/request-call/success'))
        .catch(() => navigate('/error'));
    } else setTouched(true);
  };

  return (
    <PageWrapper
      p={isMobile ? '120px 8px 8px' : '36px 40px'}
      column
      gap="0px"
      isMobile={isMobile}
      hideDiscount
      backgroundColor="green"
    >
      {isMobile ? (
        <>
          <StandMobile onlyGradient backgroundColor="green" />
          <Typography
            m="0 0 24px"
            fz={24}
            lh={28}
            fw={700}
            center
            color="#FFFFFF"
          >
            Введите свой номер
            <br /> телефона
          </Typography>
        </>
      ) : (
        <Typography
          m="auto 0 32px 0"
          fz={40}
          lh={48}
          fw={700}
          ls="-0.02em"
          center
          color="#FFFFFF"
        >
          Введите свой номер
          <br /> телефона
        </Typography>
      )}
      <PhoneInput>
        <InputMask
          className={[isPhoneValid || !isTouched ? '' : 'error'].join(' ')}
          mask="+7(999)99-999-99"
          placeholder="+7"
          value={phone}
          onChange={(v) => {
            setPhone(v.target.value);
            setPhoneValid(
              v.target.value.replaceAll(/[-+()_]/g, '').length === 11
            );
          }}
        />
      </PhoneInput>
      <TextArea
        className={[isQuestionValid || !isTouched ? '' : 'error'].join(' ')}
        placeholder="Напишите ваш вопрос..."
        value={question}
        onChange={(e) => {
          if (e.target.value.length < 255) {
            setQuestion(e.target.value);
            setQuestionValid(!!e.target.value.length);
          }
        }}
      />
      <Button
        withGradientBg
        mw={isMobile ? '338px' : '260px'}
        p={isMobile ? '19px 32px' : '30px 14px'}
        radius={isMobile ? 16 : 24}
        fz={isMobile ? 16 : 20}
        lh={isMobile ? 20 : 30}
        fw={500}
        ls="-0.02em"
        color={lightGreen}
        borderColor={darkGreen}
        onClick={handleClick}
        m="0 0 40px"
      >
        Заказать звонок
      </Button>
      {!isMobile && <BackBtn absolute />}
      <Typography
        ff="SB Sans Interface"
        fz={isMobile ? 12 : 10}
        lh={isMobile ? 14 : 12}
        fw={400}
        ls={isMobile ? '-0.03em' : undefined}
        center
        color={
          isMobile ? 'rgba(255, 255, 255, 0.35)' : 'rgba(255, 255, 255, 0.2)'
        }
        m={isMobile ? '-18px 0 26px' : 'auto max(180px, 25.54%) 0'}
      >
        Нажимая «заказать звонок», вы даёте своё согласие на обработку
        персональных данных ООО СК “Сбербанк Страхование” (121170, г. Москва,
        ул. Поклонная, д. 3, корпус 1, 1 этаж, помещение 3) (далее-Страховщик) и
        ПАО Сбербанк (117997, г. Москва, ул. Вавилова, д. 19) с совершением
        следующих действий (операций) или их совокупности, со средствами
        автоматизации или без них, в том числе сбор, запись, систематизация,
        накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, блокирование, удаление, уничтожение и передача
        (предоставление, доступ) для информирования о страховых продуктах и
        услугах Страховщика, улучшения клиентского опыта, участия в
        маркетинговых, рекламных акциях и исследованиях в том числе путем
        осуществления прямых контактов с помощью средств связи.
      </Typography>
    </PageWrapper>
  );
};
