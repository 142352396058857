export const theme = {
  breakpoints: {
    mobile: '@media (max-width: 992px)',
  },
  colors: {
    text: {
      black: '#1F1F22',
      lightGreen: '#BEF8EC',
      gray: '#565B62',
      lightGray: '#B2B8BF',
      yellow: '#FFB13B',
      malachite: '#21A19A',
      cream: '#FFF5E6',
      asphalt: '#D0D7DD',
      hintGray: '#F2F4F7',
      blue: '#6CB0FF',
      red: '#EF5959',
      orangeError: '#FA382C',
      insuranceCrossed: '#949494',
      aboutGray: 'rgba(255, 255, 255, 0.4)',
    },
    background: {
      page: '#050916',
      lightGray: 'rgba(0, 0, 0, 0.1)',
      green: '#38FCB5',
      darkGreen: 'rgb(16, 127, 140)',
      darkGreenHover: 'rgba(16, 127, 140, 0.3)',
      orange: '#FF6C57',
      blue: '#5786FF',
      darkOrange: '#F04931',
      borderGray: '#E4E8EB',
      hint: '#0D161E',
      gray: 'rgba(248, 248, 248, 0.1)',
      indicator: '#12314e',
    },
    border: {
      malachite: '#107F8C',
      mobileBackBtn: 'rgba(208, 215, 221, 0.1)',
      discount: '#E4E8EB',
      accidentCard: '#d0d7dd',
      graphite: '#1F1F22',
      shadow: '#11FFD5',
      lightGreen: '#BDF7EC',
      mobileLightGreen: '#BDF6EC',
      lightGray: '#7D838A',
      lightYellow: ' #F7C144',
      orange: '#FA382C',
      lineGray: 'rgba(255, 255, 255, 0.2)',
    },
    promocode: {
      lightGray: 'rgba(255, 255, 255, 0.5)',
      background: '#F8F8F8',
      border: '#DFDFDF',
      button: '#282841',
    },
    gradient: {
      button:
        'linear-gradient(127.3deg,rgba(16, 88, 100, 1) 19.22%,rgba(7, 61, 70, 0.38) 65.55%)',
      buttonHover:
        'linear-gradient(127.3deg,rgba(16, 88, 100, 0.3) 19.22%,rgba(7, 61, 70, 0.114) 65.55%)',
      green:
        'radial-gradient(67.72% 72.95% at 0% 0%, #107F8C 0%, rgba(16, 127, 140, 0) 100%)',
      blue: 'radial-gradient(67.72% 72.95% at 0% 0%, #2965FF 0%, rgba(41, 101, 255, 0) 100%)',
      red: 'radial-gradient(67.72% 72.95% at 0% 0%, #B14646 0%, rgba(177, 70, 70, 0) 100%)',
      orange:
        'radial-gradient(67.72% 72.95% at 0% 0%, #FF9900 0%, rgba(255, 153, 0, 0) 100%)',
      bubble:
        'radial-gradient(62.66% 95.55% at 24.68% 27.22%, #0B4956 0%, #050B18 100%)',
      bubbleOrange:
        'radial-gradient(62.66% 95.55% at 24.68% 27.22%, #BB7000 0%, #1F1505 100%)',
      indicator: 'linear-gradient(270deg, #83FFF8 0%, #107F8C 100%)',
      default: 'transparent',
    },
    shadow: {
      bubble:
        '0px 4px 44px rgba(17, 44, 42, 0.2),inset -4px -4px 24px rgba(255, 255, 255, 0.08),inset 4px 4px 34px rgba(172, 241, 252, 0.1)',
    },
    mobileGradient: {
      green:
        'radial-gradient(117.09% 115.08% at -42.64% -46.64%, #107F8C 0%, rgba(16, 127, 140, 0) 100%)',
      blue: 'radial-gradient(117.09% 115.08% at -42.64% -46.64%, #2965FF 0%, rgba(41, 101, 255, 0) 100%)',
      orange:
        'radial-gradient(117.09% 115.08% at -42.64% -46.64%, #FF9900 0%, rgba(255, 153, 0, 0) 100%)',
      red: 'radial-gradient(117.09% 115.08% at -42.64% -46.64%, #B14646 0%, rgba(177, 70, 70, 0) 100%)',
      default: 'transparent',
    },
  },
};
