import { ReactNode } from 'react';
import styled from 'styled-components';
import { StandMainPage } from '../../models';
import { BackBtn } from '../buttons/BackBtn';
import greenCircleImg from '../../assets/images/stand-circle/stand-circle-green.png';

const StyledComponent = styled.div<{ p?: string }>`
  padding: ${({ p }) => p || 'min(125px,6.51vw) 0 0'};
  width: 100vw;
  max-width: min(765px, 100%);
  max-height: min(765px, 100%);
  min-width: min(400px, 100%);
  min-height: min(765px, 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const StandWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
`;

const CircleImg = styled.div<{ img: string }>`
  width: 100vw;
  aspect-ratio: 1 / 1;
  max-width: 100%;
  background: url(${({ img }) => img}) no-repeat;
  background-size: contain;
`;

const MainImg = styled.div<StandMainPage>`
  width: ${({ w }) => `${w}`};
  height: ${({ h }) => `${h}`};
  bottom: ${({ b }) => `${b}`};
  background: url(${({ img }) => img}) no-repeat;
  background-size: contain;
  z-index: 0;
  position: absolute;
  left: ${({ left }) => (left ? `${left}%` : '50%')};
  transform: translateX(-50%);
`;

export const Stand = ({
  person,
  circleImage,
  children,
  hideCircle,
  withBackBtn,
  p,
}: {
  person: StandMainPage;
  circleImage?: string;
  children?: ReactNode;
  hideCircle?: boolean;
  withCoins?: boolean;
  withBackBtn?: boolean;
  p?: string;
}) => (
  <StyledComponent p={p}>
    <StandWrapper className="stand-wrapper">
      {!hideCircle && <CircleImg img={circleImage ?? greenCircleImg} />}
      <MainImg {...person} />
      {children}
    </StandWrapper>
    {withBackBtn && <BackBtn />}
  </StyledComponent>
);
