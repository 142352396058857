import styled from 'styled-components';
import { getShortenedCost } from '../../services';
import { theme } from '../../styles';
import { Typography } from '../common/Typography';
import iconTwo from '../../assets/images/icon/icon-two.png';
import iconFour from '../../assets/images/icon/icon-four.png';
import iconFive from '../../assets/images/icon/icon-five.png';
import iconSeven from '../../assets/images/icon/icon-seven.png';

type Props = {
  compensationName: string;
  compensationSize: number;
  riskDescription: string;
  riskTitle: string;
  color?: string;
  w?: number;
  h?: string;
  isMobile?: boolean;
};

const StyledComponent = styled.div<Props>`
  width: ${({ w }) => w && `${w}px`};
  min-height: ${({ h }) => h && `${h}`};
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${({ theme: t }) => t.colors.background.hint};
  & > div {
    display: flex;
  }
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon {
    width: 32px;
    height: 32px;
    ${theme.breakpoints.mobile} {
      width: 24px;
      height: 24px;
      margin-top: 3px;
    }
  }
  ${theme.breakpoints.mobile} {
    background: transparent;
    border: 1px solid rgba(86, 91, 98, 0.4);
    padding: 16px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const RisksHintCard = (props: Props) => {
  const {
    border: { accidentCard },
  } = theme.colors;
  const {
    riskDescription,
    riskTitle,
    isMobile,
    compensationSize,
    compensationName,
    color,
  } = props;

  const getIconByTitle = (title: string) => {
    if (title === 'Ущерб имуществу') return iconFive;
    if (title === 'Ответственность перед третьими лицами') return iconTwo;
    if (title === 'Перерыв в деятельности') return iconSeven;
    if (title === 'Расходы на юридическую защиту') return iconFour;
    return iconFour;
  };

  return (
    <StyledComponent {...props} className="risk-hint-card">
      <div>
        {isMobile ? (
          <CardHeader>
            <Typography
              ff="SB Sans Interface"
              fz={20}
              lh={28}
              fw={400}
              color="#ffffff"
              ls="-0.3px"
              m="0 10px 0 0"
            >
              {riskTitle}
            </Typography>
            <img className="icon" alt="icon" src={getIconByTitle(riskTitle)} />
          </CardHeader>
        ) : (
          <>
            <img className="icon" alt="icon" src={getIconByTitle(riskTitle)} />
            <Typography
              ff="inherit"
              fz={20}
              lh={26}
              color="#ffffff"
              m="2px 0 0 12px"
            >
              {riskTitle}
            </Typography>
          </>
        )}
      </div>
      {isMobile && (
        <Typography fz={16} lh={20} color={color} m="4px 0 12px">
          {getShortenedCost(compensationSize)} ₽
        </Typography>
      )}
      <Typography
        ff="SB Sans Interface"
        fw={400}
        fz={isMobile ? 14 : 20}
        lh={isMobile ? 18 : 28}
        ls={isMobile ? undefined : '-0.3px'}
        color={accidentCard}
        m={isMobile ? '0px' : '10px 0 0'}
      >
        {riskDescription}
      </Typography>
    </StyledComponent>
  );
};
