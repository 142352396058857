import { theme } from '../styles';
import standCircleOrange from '../assets/images/stand-circle/stand-circle-orange.png';
import standCircleBlue from '../assets/images/stand-circle/stand-circle-blue.png';
import standCircleRed from '../assets/images/stand-circle/stand-circle-red.png';

export const getDamageDataByEvent = (
  event?: string
): {
  color: string;
  title: string;
  backgroundColor: 'green' | 'blue' | 'red' | 'orange' | 'default';
  circleImage: string;
} => {
  const { yellow, blue, red } = theme.colors.text;

  switch (event) {
    case 'fire':
      return {
        color: yellow,
        title: 'Произошёл пожар',
        backgroundColor: 'orange',
        circleImage: standCircleOrange,
      };
    case 'flooding':
      return {
        color: blue,
        title: 'Произошёл залив',
        backgroundColor: 'blue',
        circleImage: standCircleBlue,
      };
    case 'IllegalActions':
      return {
        color: red,
        title: 'Произошло непредвиденное событие',
        backgroundColor: 'red',
        circleImage: standCircleRed,
      };
    case 'equipmentBreakdown':
      return {
        color: red,
        title: 'Произошла поломка оборудования',
        backgroundColor: 'red',
        circleImage: standCircleRed,
      };
    case 'accident':
      return {
        color: blue,
        title: 'Произошёл несчастный случай',
        backgroundColor: 'blue',
        circleImage: standCircleBlue,
      };
    case 'legalProtection':
      return {
        color: yellow,
        title: 'Произошло непредвиденное событие',
        backgroundColor: 'orange',
        circleImage: standCircleOrange,
      };
    case 'qualityResponsibility':
      return {
        color: blue,
        title: 'Произошло непредвиденное событие',
        backgroundColor: 'blue',
        circleImage: standCircleBlue,
      };
    default:
      return {
        color: 'default',
        title: 'Произошёл пожар',
        backgroundColor: 'default',
        circleImage: 'default',
      };
  }
};

export const getShortenedCost = (cost: number) => {
  let result = cost;
  if (cost > 1000000) {
    result = +(result / 1000000).toFixed(1);
    return `- ${result} млн`;
  }
  const thousands = Math.round(result / 1000);
  return `- ${thousands} тыс.`;
};
