import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { theme } from '../styles';
import { getSpotImageData, postEvent } from '../services';
import { useMobile } from '../hooks';
import api from '../http/api';
import { storyDto } from '../models/story';
import { getSplittedCost } from '../components/damage/DamageInfo';

import storyShopSmall from '../assets/images/story/story-shop-small.png';
import storyManufactureSmall from '../assets/images/story/story-manufacture-small.png';
import storyCafeSmall from '../assets/images/story/story-cafe-small.png';
import storyRentSmall from '../assets/images/story/story-rent-small.png';
import storyCafe from '../assets/images/story/story-cafe.png';
import storyManufacture from '../assets/images/story/story-manufacture.png';
import storyShop from '../assets/images/story/story-shop.png';
import storyRent from '../assets/images/story/story-rent.png';
import { getUserId } from '../services/userService';

import { PageWrapper } from '../components/layout/PageWrapper';
import { StandMobile } from '../components/common/StandMobile';
import { Typography } from '../components/common/Typography';

import { Stand } from '../components/common/Stand';
import { Column } from '../components/common/Column';
import { Button } from '../components/buttons/Button';

import { InsuranceContext } from '../context/Promo';
import { QuizContext } from '../context/Quiz';

const {
  text: { yellow },
  border: { graphite },
} = theme.colors;

const Frame = styled.div`
  border: solid 1px ${graphite};
  padding: 30px 43px 30px 32px;
  border-radius: 20px;
  margin-bottom: 179px;
  ${theme.breakpoints.mobile} {
    padding: 16px;
    margin-bottom: 18px;
  }
`;
const Line = styled.div`
  border-bottom: solid 1px ${graphite};
  border-radius: 20px;
  margin-bottom: 32px;
  ${theme.breakpoints.mobile} {
    margin: 11px 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 14px;
  margin-bottom: 33px;
  max-width: 90%;
  flex-wrap: wrap;
  justify-content: center;
  ${theme.breakpoints.mobile} {
    gap: 4px;
    max-width: 344px;
  }
`;

const Disclaimer = styled.div`
  position: absolute;
  bottom: 33px;
  left: 50%;
  font-family: 'SB Sans Interface';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.35);
  transform: translate(-50%);
  ${theme.breakpoints.mobile} {
    font-size: 10px;
    line-height: 10px;
    width: 100%;
    max-width: min(330px, 100%);
    bottom: 10px;
    text-align: center;
  }
`;

const StandMobileImages = [
  storyShopSmall,
  storyManufactureSmall,
  storyCafeSmall,
  storyRentSmall,
];

const StandImages = [storyShop, storyManufacture, storyCafe, storyRent];

export const StoryPage: React.FC = () => {
  const navigate = useNavigate();
  const id = Number(useParams().id) ?? 0;
  const nextStory = `/story/${id + 1}`;
  const location = useLocation();
  const isMobile = useMobile();
  const { malachite } = theme.colors.border;
const user = getUserId();

  const [storiesData, setStoriesData] = useState<storyDto[]>([]);

  const isLastStory = location.pathname.includes(
    String(storiesData.length - 1)
  );

  const {
    insuranceContext: {
      discountMaxPercents,
      insuranceUrl,
      discountPromo,
      discountSuperpromo,
    },
  } = useContext(InsuranceContext);
  const { quizContext } = useContext(QuizContext);

  useEffect(() => {
    api
      .get(`story/getRealStories?businessType=${quizContext.businessType ?? 0}`)
      .then(({ data }) => setStoriesData(data))
      .catch(() => navigate('/error'));
  }, [quizContext]);

  const getStandImage = useMemo(
    () =>
      isMobile
        ? StandMobileImages[quizContext.businessType]
        : StandImages[quizContext.businessType],
    [isMobile, quizContext.businessType]
  );

  const getPromocode = (): string =>
    discountMaxPercents === 0.27 ? 'GAMIFICATION40' : 'SUPERPROMO';
  const getPromocodeUrl = () => {
    const expectedPrice = (
      discountMaxPercents === 0.27 ? discountPromo : discountSuperpromo
    ).toFixed(2);
    return `${insuranceUrl}&expectedPrice=${expectedPrice}&promoCode=${getPromocode()}`;
  };

  if (storiesData.length === 0) return null;

  return (
    <PageWrapper
      p={isMobile ? '0px 8px 37px 8px' : '104px 40px 36px'}
      isMobile={isMobile}
      column={isMobile}
      hideDiscount
      backgroundColor="green"
      gap="90px"
    >
      {isMobile ? (
        <>
          <StandMobile
            top="-64px"
            img={getStandImage}
            story
            backgroundColor="green"
          />
          <Typography
            m="4px 0px 14px 0"
            mw={322}
            fz={24}
            lh={28}
            fw={700}
            center
            color="#FFFFFF "
          >
            Реальное событие с владельцем бизнеса
          </Typography>
          <Frame>
            <Typography
              mw={312}
              fw={400}
              fz={14}
              lh={18}
              color="#FFFFFF "
              ff="SB Sans Interface"
            >
              {storiesData[id].description}
            </Typography>
            <Line />
            <Typography
              fw={400}
              fz={16}
              lh={22}
              color="#FFFFFF"
              ff="SB Sans Interface"
            >
              Общие потери
            </Typography>
            <Typography fz={20} lh={30} fw={700} ls="-0.02em" color={yellow}>
              -{getSplittedCost(+storiesData[id].moneyLost)}&nbsp;₽
            </Typography>
          </Frame>
          <ButtonWrapper>
            <Button
              withGradientBg
              mw="344px"
              h={58}
              radius={16}
              fz={16}
              lh={20}
              fw={500}
              ls="-0.02em"
              borderColor={malachite}
              onClick={() => {
                postEvent({ user, eventId: 9 }).catch(() => navigate('/error'));
                const link = document.createElement('a');
                link.href = getPromocodeUrl();
                document.body.appendChild(link);
                link.click();
              }}
            >
              Забрать скидку и застраховаться
            </Button>
            {!isLastStory && (
              <Button
                mw="344px"
                h={58}
                radius={16}
                fz={16}
                lh={20}
                fw={500}
                ls="-0.02em"
                borderColor={malachite}
                onClick={() => navigate(nextStory)}
              >
                Посмотреть реальные истории
              </Button>
            )}
          </ButtonWrapper>
        </>
      ) : (
        <>
          <Stand
            person={getSpotImageData({ img: getStandImage })[5]}
            hideCircle
            withBackBtn
            p="0"
          />
          <Column p="min(28px,1.46vw) 0 0">
            <Typography
              m="0 0px 40px 0"
              mw={765}
              fz={60}
              lh={64}
              fw={700}
              center
              color="#FFFFFF "
            >
              Реальное событие с владельцем бизнеса
            </Typography>
            <Frame>
              <Typography
                m="0 0px 32px 0"
                mw={708}
                fw={400}
                fz={24}
                lh={34}
                color="#FFFFFF "
                ff="SB Sans Interface"
              >
                {storiesData[id].description}
              </Typography>
              <Line />
              <Typography
                m="0 0 10px 0"
                fw={400}
                fz={21}
                lh={32}
                color="#FFFFFF"
                ff="SB Sans Interface"
              >
                Общие потери
              </Typography>
              <Typography fz={50} lh={50} fw={700} ls="-0.02em" color={yellow}>
                -{getSplittedCost(+storiesData[id].moneyLost)}&nbsp;₽
              </Typography>
            </Frame>
            <ButtonWrapper>
              <Button
                withGradientBg
                mw="260px"
                h={124}
                radius={24}
                fz={20}
                lh={30}
                fw={500}
                ls="-0.02em"
                borderColor={malachite}
                onClick={() => {
                  postEvent({ user, eventId: 9 }).catch(() =>
                    navigate('/error')
                  );
                  const link = document.createElement('a');
                  link.href = getPromocodeUrl();
                  document.body.appendChild(link);
                  link.click();
                }}
              >
                Забрать скидку и застраховаться
              </Button>
              {!isLastStory && (
                <Button
                  mw="260px"
                  h={124}
                  radius={24}
                  fz={20}
                  lh={30}
                  fw={500}
                  ls="-0.02em"
                  borderColor={malachite}
                  onClick={() => navigate(nextStory)}
                >
                  Посмотреть реальные истории
                </Button>
              )}
            </ButtonWrapper>
          </Column>
        </>
      )}
      <Disclaimer>
        На основнии информации ООО СК «Сбербанк страхование» по страховым
        событиям за 2021-2022 годы
      </Disclaimer>
    </PageWrapper>
  );
};
