import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import greenMobileCircleImg from '../../assets/images/stand-circle/stand-mobile-circle-green.png';

type Props = {
  img?: string;
  circleImage?: string;
  backgroundColor?: 'green' | 'blue' | 'orange' | 'red' | 'default';
  p?: string;
  story?: boolean;
  top?: string;
  onlyGradient?: boolean;
};

const StyledComponent = styled.div<{ story?: boolean }>`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: ${({ story }) => (story ? '210px' : '240px')};
  ${({ story }) =>
    story &&
    css`
      margin-bottom: -12px;
    `}
`;

const GradientLayer = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: ${({ theme: t, backgroundColor }) =>
    t.colors.mobileGradient[backgroundColor ?? 'default']};
  width: 100%;
  aspect-ratio: 360 / 416;
`;

const ShadowCirlce = styled.div<{ story?: boolean }>`
  background: radial-gradient(
    49.14% 49.14% at 50% 50%,
    rgba(0, 0, 0, 0) 83.85%,
    #050916 100%
  );
  width: 304px;
  height: 240px;
  position: absolute;
  z-index: 0;
  ${({ story }) =>
    story &&
    css`
      width: 242px;
      height: 192px;
    `}
`;

const CircleImg = styled.div<{ img: string; story?: boolean }>`
  position: absolute;
  width: 300px;
  height: 226px;
  bottom: 0;
  background: url(${({ img }) => img}) no-repeat;
  background-size: contain;
  ${({ story }) =>
    story &&
    css`
      width: 242px;
      height: 191px;
    `}
`;

const MainImg = styled.div<{ img?: string; story?: boolean; index?: number }>`
  width: 243px;
  height: 192px;
  margin-top: auto;
  ${({ story }) =>
    story &&
    css`
      background-size: contain;
      background-repeat: no-repeat;
      width: 112px;
      height: 112px;
      margin-top: 79px;
    `}
  ${({ img }) => img && `background: url(${img})`};
  background-size: cover;
  z-index: 0;
  background-position: ${({ index = 1 }) => `${index * 243.2}px 0`};
`;

export const StandMobile = (props: Props) => {
  const { circleImage, onlyGradient, story, img } = props;

  const [characterIndex, setCharacterIndex] = useState(0);

  useEffect(() => {
    if (story) return () => {};
    let animationFrameId: number;
    let previousTime = performance.now();

    const changeImage = (currentTime: number) => {
      if (currentTime - previousTime >= 30) {
        setCharacterIndex((index) => (index + 1) % 84);
        previousTime = currentTime;
      }
      animationFrameId = requestAnimationFrame(changeImage);
    };

    animationFrameId = requestAnimationFrame(changeImage);
    return () => cancelAnimationFrame(animationFrameId);
  }, [characterIndex]);

  return onlyGradient ? (
    <GradientLayer className="stand" {...props} />
  ) : (
    <>
      <GradientLayer className="stand" {...props} />
      <StyledComponent className="stand" story={story}>
        <CircleImg img={circleImage ?? greenMobileCircleImg} story={story} />
        <MainImg img={img} story={story} index={characterIndex} />
        <ShadowCirlce story={story} />
      </StyledComponent>
    </>
  );
};
