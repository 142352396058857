import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useContext, useEffect } from 'react';

import { theme } from '../styles';
import { getSpotImageData, postEvent } from '../services';
import { useMobile } from '../hooks';
import spritesheet from '../assets/images/character/spritesheet-character-five.png';
import { getUserId } from '../services/userService';
import { QuizContext } from '../context/Quiz';
import { CalculateDto } from '../models';
import { PageTitle } from '../components/layout/PageTitle';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StandMobile } from '../components/common/StandMobile';
import { Typography } from '../components/common/Typography';
import { WithBorder } from '../components/common/WithBorder';
import { Stand } from '../components/common/Stand';
import { Column } from '../components/common/Column';
import { Button } from '../components/buttons/Button';

const Disclaimer = styled.div`
  position: absolute;
  bottom: 38px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  max-width: 774px;
  font-family: 'SB Sans Interface';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.35);
  font-size: 12px;
  a {
    font-family: 'SB Sans Interface';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.35);
    font-size: 12px;
    text-decoration: underline;
  }
  ${theme.breakpoints.mobile} {
    font-size: 10px;
    line-height: 10px;
    width: 100%;
    max-width: min(600px, 100%);
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    padding: 0 8px;
    text-align: center;
  }
`;

export const MainPage = () => {
  const { pathname } = useLocation();
  const { lightGreen } = theme.colors.text;
  const isMobile = useMobile();
  const navigate = useNavigate();
  const user = getUserId();
  const { setQuizContext } = useContext(QuizContext);

  useEffect(() => {
    if (user > 0)
      postEvent({ user, eventId: 0 }).catch(() => navigate('/error'));
  }, [user]);

  useEffect(() => {
    if (pathname === '/admin') window.location.reload();
    else if (pathname.includes('admin') && pathname !== '/admin')
      navigate('/admin');
  }, [pathname]);

  const handleStartClick = () => {
    postEvent({ user, eventId: 1 }).catch(() => navigate('/error'));
    setQuizContext(
      (prev) => ({ ...prev, businessType: 0 } as CalculateDto) // сразу тип бизнеса - розничн торговля
    );
    postEvent({ user, eventId: 2 }).catch(() => navigate('/error'));
    navigate('/select-facility');
  };

  return (
    <PageWrapper
      p={isMobile ? '0 45px 115px' : '0 min(180px,9.37vw) 120px 40px'}
      isMobile={isMobile}
      column={isMobile}
      hideDiscount
      hideBackBtn
      backgroundColor="green"
    >
      {isMobile ? (
        <>
          <StandMobile img={spritesheet} backgroundColor="green" />
          <PageTitle m="-8px 0 24px" fz={24} lh={28} mw={360} fw={700}>
            Спорим, не всё под&nbsp;контролем?
          </PageTitle>
          <WithBorder borderRadius={28} p="24px 17px" mw="270px">
            <Typography
              mw={270}
              lh={24}
              fw={400}
              ls="-0.02em"
              fz={16}
              color={lightGreen}
              center
            >
              80% бизнесменов не&nbsp;осознают масштабов рисков своего бизнеса
            </Typography>
          </WithBorder>
          <WithBorder
            borderRadius={16}
            m="84px 0 0"
            p="3px"
            mw="270px"
            w="100%"
          >
            <Button
              p="18px"
              lh={24}
              fw={500}
              fz={17}
              ls="-0.02em"
              withGradientBg
              radius={12}
              mw="100%"
              onClick={handleStartClick}
            >
              Проверьте, чем&nbsp;рискуете
            </Button>
          </WithBorder>
        </>
      ) : (
        <>
          <Stand person={getSpotImageData()[0]} />
          <Column mw={725} p="min(218px,11.35vw) 0 0">
            <PageTitle mw={765} m="0 0 48px 0" fz={80} lh={90} fw={700}>
              Спорим, не&nbsp;всё под контролем?
            </PageTitle>
            <WithBorder borderRadius={28} p="32px 46px">
              <Typography
                mw={710}
                lh={56}
                fw={400}
                ls="-0.02em"
                fz={48}
                color={lightGreen}
                center
              >
                80% бизнесменов не&nbsp;осознают масштабов рисков своего бизнеса
              </Typography>
            </WithBorder>
            <WithBorder borderRadius={31} m="76px 0 0">
              <Button
                p="20px 50px"
                lh={40}
                fw={500}
                fz={32}
                ls="-0.02em"
                withGradientBg
                mw="311px"
                radius={24}
                onClick={handleStartClick}
              >
                Проверьте, чем&nbsp;рискуете
              </Button>
            </WithBorder>
          </Column>
        </>
      )}
      <Disclaimer>
        Услуги по страхованию оказывает ООО СК «Сбербанк страхование», лицензии
        Банка России СИ № 4331, СЛ № 4331 выдана 10.06.2021 бессрочно. ОГРН
        1147746683479. Адрес: Москва, ул. Поклонная, д. 3, корп. 1, 1 этаж,
        помещение 3. Реклама. Рекламодатель ООО СК «Сбербанк страхование»{' '}
        <a href="https://www.sberbankins.ru" target="_blank" rel="noreferrer">
          www.sberbankins.ru
        </a>
      </Disclaimer>
    </PageWrapper>
  );
};
