import api from '../http/api';

export const getUserId = () => {
  let result = localStorage.getItem('userId')
    ? +localStorage.getItem('userId')!!
    : 0;
  console.log('userId from localstorage', result);
  if (!result)
    api.post('stat/getId').then(({ data }) => {
      console.log('userId from stat/getId', data);
      localStorage.setItem('userId', data);
      result = data;
    });
  // .catch(() => {
  //   if (!window.location.pathname.includes('error'))
  //     window.location.href += 'error';
  // });
  return result;
};;
