import styled from 'styled-components';
import { theme } from '../../styles';

export const ChoiceCardsContainer = styled.div`
  display: flex;


  justify-content: center;
  align-items: center;
  gap: 24px;
  ${theme.breakpoints.mobile} {
    gap: 8px;
    max-width: 322px;
  }
`;
