import styled, { css } from 'styled-components';
import { ReactNode } from 'react';
import { theme } from '../../styles';

type Props = {
  children: ReactNode;
  p?: string;
  m?: string;
  mw?: string;
  w?: string;
  h?: number;
  borderRadius?: number;
  borderColor?: string;
  shadow?: string;
  onClick?: () => void;
};

const Styled = styled.div<Props>`
  padding: ${({ p }) => (p ? `${p}` : '8px')};
  position: relative;
  border: 2px solid
    ${({ theme: t, borderColor }) => borderColor ?? t.colors.border.lightGreen};
  box-shadow: ${({
    theme: {
      colors: { border },
    },
    shadow,
  }) => shadow ?? `${border.shadow} 0 0 5px inset, ${border.shadow} 0 0 10px`};

  ${({ mw }) =>
    mw &&
    css`
      max-width: ${mw};
    `};
  ${({ m }) =>
    m &&
    css`
      margin: ${m};
    `};
  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius}px;
    `};
  ${({ mw }) =>
    mw &&
    css`
      max-width: ${mw};
    `};
  ${({ w }) =>
    w &&
    css`
      width: ${w};
    `};
  ${({ h }) =>
    h &&
    css`
      height: ${h}px;
    `};
  &::after {
    content: '';
    position: absolute;
    right: 15%;
    top: -15px;
    width: 6%;
    height: 20px;
    background-color: ${({ theme: t }) => t.colors.background.page};
  }
  ${theme.breakpoints.mobile} {
    &::after {
      height: 9px;
      right: 14%;
      top: -5px;
      width: 5%;
    }
    border: 2px solid
      ${({ theme: t, borderColor }) =>
        borderColor ?? t.colors.border.mobileLightGreen};
    box-shadow: ${({
      theme: {
        colors: { border },
      },
      shadow,
    }) =>
      shadow ??
      `${border.mobileLightGreen} 0 0 5px inset, ${border.mobileLightGreen} 0 0 5px`};
  }
`;

export const WithBorder = (props: Props) => <Styled {...props} />;
