import styled, { css } from 'styled-components';
import { useMobile } from '../../hooks';
import { theme } from '../../styles';
import { Typography } from '../common/Typography';
import { getSplittedCost } from '../damage/DamageInfo';

type Props = {
  icon: string;
  title: string;
  description: string;
  sum: number;
  numberOfEmployees?: number;
  w?: number;
  h?: number;
};

const StyledComponent = styled.div<Props>`
  ${({ w }) =>
    w &&
    css`
      min-width: ${w}px;
      width: ${w}px;
      ${theme.breakpoints.mobile} {
        width: unset;
      }
    `};
  ${({ h }) =>
    h &&
    css`
      height: ${h}px;
    `};
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid ${({ theme: t }) => t.colors.border.graphite};
  & > div {
    display: flex;
  }
  .icon {
    width: 32px;
    height: 32px;
  }
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${theme.breakpoints.mobile} {
    width: 100%;
    height: 100%;
    padding: 16px;
    .icon {
      width: 24px;
      height: 24px;
    }
  }
`;

const InsuranceCost = styled.span`
  font-family: 'SB Sans Text';
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: rgba(144, 208, 204, 1);
`;

export const InsuranceHintCard = (props: Props) => {
  const {
    border: { accidentCard },
    text: { hintGray },
  } = theme.colors;
  const { icon, title, description, numberOfEmployees, sum } = props;

  const isMobile = useMobile();

  return (
    <StyledComponent {...props}>
      <div>
        <img src={icon} alt="icon" className="icon" />
        <Typography
          ff="SB Sans Interface"
          fz={isMobile ? 18 : 21}
          lh={isMobile ? 24 : 32}
          fw={400}
          color={hintGray}
          m="0 0 0 12px"
        >
          {title}
        </Typography>
      </div>
      <Typography
        ff={isMobile ? 'SB Sans Text' : 'SB Sans Interface'}
        fw={400}
        fz={isMobile ? 14 : 16}
        lh={isMobile ? 18 : 22}
        color={accidentCard}
        m={isMobile ? '6px 0 0' : '10px 0 0'}
      >
        {description}
      </Typography>
      <Typography m="10px 0 0" fz={16} lh={18} fw={500} color="#F2F4F7">
        Страховая сумма&nbsp;
        <InsuranceCost>{getSplittedCost(sum)}</InsuranceCost>&nbsp;₽
      </Typography>
      {title === 'Защита сотрудников' && (
        <Typography m="6px 0 0" fz={16} lh={18} fw={500} color="#F2F4F7">
          Количество сотрудников - {numberOfEmployees}
        </Typography>
      )}
      {title === 'Защита активов' && (
        <Typography m="6px 0 0" fz={16} lh={18} fw={500} color="#F2F4F7">
          Количество объектов страхования&nbsp;-&nbsp;1
        </Typography>
      )}
    </StyledComponent>
  );
};
