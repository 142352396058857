import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Typography } from '../common/Typography';
import { WithBlur } from '../common/WithBlur';
import pigImage from '../../assets/images/pig/pig_142.png';

const StyledComponent = styled.div<{ img?: string; index?: number }>`
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translateX(-50%);
  min-width: min(60.66%, 464px);
  .discount-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 24px;
    column-gap: 68px;
    div:last-child {
      min-width: 133.4px;
    }
  }
`;

const PigImg = styled.div<{
  img?: string;
  index?: number;
}>`
  z-index: 2;
  ${({ img }) => img && `background: url(${img})`};
  margin-top: -70px;
  width: 112px;
  height: 185px;
  background-position: ${({ index = 1 }) => `${(80 - index) * 112}px 0`};
`;

export const BlurredDiscountBlock = ({
  percents,
  pigIndexDefault,
}: {
  percents: number;
  pigIndexDefault?: null | number;
}) => {
  const [pigIndex, setPigIndex] = useState(pigIndexDefault ?? 0);
  useEffect(() => {
    if (pigIndexDefault) setPigIndex((prev) => 1);
  }, [pigIndexDefault]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (pigIndex === 80) clearInterval(interval);
      else if (pigIndex !== 0)
        setPigIndex((prev) => (prev === 79 ? 0 : prev + 1));
    }, 30);
    return () => clearInterval(interval);
  }, [pigIndex]);

  return (
    <StyledComponent>
      <WithBlur center p="32px 32px 24px">
        <Typography
          fz={32}
          lh={40}
          fw={300}
          ls="-0.02em"
          color="#ffffff"
          m="0 0 16px"
        >
          Твоя скидка на кое-что очень важное...
        </Typography>
        <div className="discount-block">
          <PigImg img={pigImage} index={pigIndex} />
          <div>
            <Typography fz={60} lh={66} fw={600} color="#ffffff" center>
              {percents}%
            </Typography>
          </div>
        </div>
      </WithBlur>
    </StyledComponent>
  );
};
