import { ReactNode } from 'react';
import { Typography } from '../common/Typography';

type Props = {
  children: ReactNode;
  className?: string;
  fz?: number;
  m?: string;
  p?: string;
  fw?: number;
  lh?: string | number;
  ls?: string;
  ff?: string;
  mw?: number;
  color?: string;
  center?: boolean;
  border?: boolean;
};

export const PageTitle = (props: Props) => (
  <Typography fz={40} lh={64} color="#ffffff" center {...props} />
);
