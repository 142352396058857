import { QuizData } from '../models';

export const getQuizQuestions: QuizData[] = [
  {
    title: 'Для оценки рисков бизнеса расскажите про свои активы',
    subTitle: 'Недвижимость, которую вы используете в бизнесе',
    question: 'Какая стоимость недвижимости?',
    answers: [
      { text: 'до 5 млн ₽', value: 5000000 },
      { text: '5-25 млн ₽', value: 25000000 },
      { text: '25-50 млн ₽', value: 50000000 },
      { text: '50-100 млн ₽', value: 100000000 },
      { text: 'более 100 млн ₽', value: 200000000 },
    ],
    field: 'estateCost',
    selected: '',
  },
  {
    title: 'Для оценки рисков бизнеса расскажите про свои активы',
    subTitle: 'Недвижимость, которую вы используете в бизнесе',
    question: `Какая площадь\nнедвижимости?`,
    answers: [
      { text: 'до 50 кв. м', value: 50 },
      { text: '50-100 кв. м', value: 100 },
      { text: '100-250 кв. м', value: 250 },
      { text: '250-500 кв. м', value: 500 },
      { text: 'более 500 кв. м', value: 1000 },
    ],

    field: 'estateArea',
    selected: '',
  },
  {
    title: 'Для оценки рисков бизнеса расскажите про свои активы',
    question: 'Объект недвижимости в собственности?',
    answers: [
      { text: 'Да', value: 0 },
      { text: 'Нет, арендую', value: 1 },
    ],
    field: 'isRented',
    selected: '',
  },
  {
    title: 'Для оценки рисков бизнеса расскажите про свои активы',
    subTitle: 'Недвижимость, которую вы используете в бизнесе',
    question: 'Какая стоимость оборудования и товара на объекте?',
    answers: [
      { text: 'отсутствует', value: 0 },
      { text: 'до 5 млн ₽', value: 5000000 },
      { text: '5-25 млн ₽', value: 25000000 },
      { text: '25-50 млн ₽', value: 50000000 },
      { text: 'более 50 млн ₽', value: 100000000 },
    ],

    field: 'equipmentCost',
    selected: '',
  },

  {
    title: 'Расскажите про обязательные расходы',
    question: 'Есть ли у вашего бизнеса кредит?',
    answers: [
      { text: 'Да', value: 1 },
      { text: 'Нет', value: 0 },
    ],
    field: 'outcomePayment',
    selected: '',
  },
  {
    title: 'Расскажите про обязательные расходы',
    question: 'Какой размер платежа по кредиту в месяц?',
    answers: [
      { text: 'до 50 тыс. ₽', value: 50000 },
      { text: '50-150 тыс. ₽', value: 150000 },
      { text: '150-250 тыс. ₽', value: 250000 },
      { text: '250-500 тыс. ₽', value: 500000 },
      { text: 'более 500 тыс. ₽', value: 1000000 },
    ],

    field: 'outcomePayment',
    selected: '',
  },
  {
    title: 'Расскажите про ожидаемые расходы',
    question: 'Какой размер платежа за аренду в месяц?',
    answers: [
      { text: 'до 50 тыс. ₽', value: 50000 },
      { text: '50-150 тыс. ₽', value: 150000 },
      { text: '150-250 тыс. ₽', value: 250000 },
      { text: '250-500 тыс. ₽', value: 500000 },
      { text: 'более 500 тыс. ₽', value: 1000000 },
    ],

    field: 'rentPayment',
    selected: '',
  },
  {
    title: 'Расскажите про ожидаемые доходы',
    question: 'Какой размер ежемесячных доходов от бизнеса?',
    answers: [
      { text: 'до 50 тыс. ₽', value: 50000 },
      { text: '50-150 тыс. ₽', value: 150000 },
      { text: '150-250 тыс. ₽', value: 250000 },
      { text: '250-500 тыс. ₽', value: 500000 },
      { text: 'более 500 тыс. ₽', value: 1000000 },
    ],

    field: 'incomePayment',
    selected: '',
  },
  {
    title: 'Расскажите про ожидаемые доходы',
    question: 'Какой размер ежемесячных доходов от аренды?',
    answers: [
      { text: 'до 50 тыс. ₽', value: 50000 },
      { text: '50-150 тыс. ₽', value: 150000 },
      { text: '150-250 тыс. ₽', value: 250000 },
      { text: '250-500 тыс. ₽', value: 500000 },
      { text: 'более 500 тыс. ₽', value: 1000000 },
    ],

    field: 'incomePayment',
    selected: '',
  },
  {
    title: 'Расскажите про свою компанию',
    question: 'Сколько сотрудников работает в вашей компании?',
    answers: [
      { text: 'работаю один', value: 0 },
      { text: 'до 5 сотрудников', value: 5 },
      { text: '5-15 сотрудников', value: 15 },
      { text: '15-25 сотрудников', value: 25 },
      { text: 'более 25 сотрудников', value: 50 },
    ],

    field: 'employeesCount',
    selected: '',
  },
  {
    title: 'Расскажите про свою компанию',
    question: 'Какая средняя зарплата на каждого сотрудника в месяц?',
    answers: [
      { text: 'до 20 тыс. ₽', value: 20000 },
      { text: '20-50 тыс. ₽', value: 50000 },
      { text: '50-100 тыс. ₽', value: 100000 },
      { text: '100-200 тыс. ₽', value: 200000 },
      { text: 'более 200 тыс. ₽', value: 400000 },
    ],

    field: 'employeeSalary',
    selected: '',
  },
];
