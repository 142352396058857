import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Star, CircleChecked, File } from '../assets/icons';
import { theme } from '../styles';
import { useMobile } from '../hooks';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StandMobile } from '../components/common/StandMobile';
import { Typography } from '../components/common/Typography';

import { BackBtn } from '../components/buttons/BackBtn';


const {
  text: { aboutGray },
  border: { lineGray },
} = theme.colors;

const Line = styled.div`
  border-bottom: solid 1px ${lineGray};
  margin-bottom: 30px;
  ${theme.breakpoints.mobile} {
    max-width: 328px;
    width: 100%;
    margin: 10px 0;
  }
`;

const AboutWrapper = styled.div`
  display: flex;
  margin: 0 0 30px 0;
  justify-content: space-between;
  li {
    max-width: 433px;
    width: 100%;
    list-style-type: none;
  }
  li:not(:last-child) {
    border-bottom: solid 1px ${lineGray};
    padding-bottom: 10px;
  }
  li:not(:first-child) {
    padding-top: 10px;
  }
  a {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    font-family: 'SB Sans Interface';
    color: rgba(108, 176, 255, 1);
    text-decoration: underline;
    cursor: pointer;
  }
  ${theme.breakpoints.mobile} {
    display: inline-block;
    margin-bottom: 70px;
    li {
      max-width: 328px;
    }
  }
`;

const TitleWrapper = styled.div<{ mb?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 168px;
  ${theme.breakpoints.mobile} {
    max-width: 328px;
    flex-direction: row-reverse;
    margin-bottom: ${({ mb }) => mb}px;
  }
`;

const AboutColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 628px;
  width: 100%;
  z-index: 1;
  svg {
    width: 32px;
  }
  ${theme.breakpoints.mobile} {
    align-items: center;
  }
`;

const AboutMobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 328px;
  width: 100%;
  margin-bottom: 25px;
`;

const advantages = [
  'Широкий перечень покрываемых рисков',
  'Страхование бизнеса на всей территории РФ',
  'Страхование без осмотров и дополнительных документов',
  'Онлайн консультация врача терапевта  24/7',
];

const opportunities = [
  'Возможность покупки как отдельных опций, так и полного пакета защиты по самому выгодному тарифу',
  'Страхование имущества с лимитом до 5 млн руб.',
  'Страхование ответственности перед третьими лицами до 5 млн руб.',
  'Страхование перерыва в производстве до 5 млн руб.',
  'Страхование до 50 сотрудников от несчастных случаев до 5 млн руб.',
];

export const AboutPage: React.FC = () => {
  const isMobile = useMobile();

  return (
    <PageWrapper
      p={isMobile ? '21px 16px' : '100px 40px 36px'}
      backgroundColor="green"
    >
      <StandMobile
        onlyGradient
        backgroundColor={isMobile ? 'green' : undefined}
      />
      <AboutColumn>
        {isMobile ? (
          <AboutMobileHeader>
            <Typography fz={24} lh={28} color="#FFFFFF">
              О продукте
            </Typography>
            <BackBtn cross />
          </AboutMobileHeader>
        ) : (
          <Typography
            m="0 0 40px 0"
            color="#FFFFFF"
            fz={40}
            lh={48}
            ls="-0.02em"
          >
            Защита 360
          </Typography>
        )}
        <AboutWrapper>
          <TitleWrapper mb={24}>
            <Star />
            <Typography
              m={isMobile ? '0 auto 0 0' : '11px auto 0 0'}
              color="#FFFFFF"
              fz={20}
              lh={26}
            >
              Преимущества продукта «Защита 360»
            </Typography>
          </TitleWrapper>
          <ul>
            {advantages.map((advantage) => (
              <li key={uuidv4()}>
                <Typography
                  fz={16}
                  lh={22}
                  fw={400}
                  ff="SB Sans Interface"
                  color="#FFFFFF"
                >
                  {advantage}
                </Typography>
              </li>
            ))}
          </ul>
        </AboutWrapper>
        {!isMobile && <Line />}
        <AboutWrapper>
          <TitleWrapper mb={24}>
            <CircleChecked />
            <Typography
              m={isMobile ? '0 auto 0 0' : '11px auto 0 0'}
              color="#FFFFFF"
              fz={20}
              lh={26}
            >
              Возможности <br />
              по продукту
            </Typography>
          </TitleWrapper>
          <ul>
            {opportunities.map((opportunity) => (
              <li key={uuidv4()}>
                <Typography
                  fz={16}
                  lh={22}
                  fw={400}
                  ff="SB Sans Interface"
                  color="#FFFFFF"
                >
                  {opportunity}
                </Typography>
              </li>
            ))}
          </ul>
        </AboutWrapper>
        {!isMobile && <Line />}
        <AboutWrapper>
          <TitleWrapper>
            <File />
            <Typography
              m={isMobile ? '0 auto 0 0' : '11px auto 0 0'}
              color="#FFFFFF"
              fz={20}
              lh={26}
            >
              Исключения
              <br /> по продукту
            </Typography>
          </TitleWrapper>
          {isMobile && <Line />}
          <Typography
            mw={isMobile ? 328 : 433}
            p={isMobile ? '0' : '0 43px 0 0'}
            fz={16}
            lh={22}
            fw={400}
            ff="SB Sans Interface"
            color="#FFFFFF"
          >
            {`Со списком исключений вы можете ознакомиться в `}
            <a href="https://www.sberbank.com/common/img/uploaded/mmb/insurance/360/usloviya_strakhovaniya.pdf">
              Условия страхования
            </a>
          </Typography>
        </AboutWrapper>
        <Typography
          mw={isMobile ? 328 : 628}
          m={isMobile ? '23px 0 0 0' : '30px 0 0 0'}
          ff="SB Sans Interface"
          color={aboutGray}
          fz={10}
          lh={15}
          fw={400}
        >
          Услуги по страхованию оказывает ООО СК «Сбербанк страхование»,
          лицензии Банка России СЛ № 4331, СИ №4331 выданы 12.10.2020 бессрочно.
          ОГРН 1147746683479. Адрес: Москва, ул. Поклонная, д. 3, корп. 1, 1
          этаж, пом. 3.
          <br /> Реклама. Рекламодатель ООО СК «Сбербанк страхование».
        </Typography>
      </AboutColumn>
      {!isMobile && <BackBtn absolute />}
    </PageWrapper>
  );
};
