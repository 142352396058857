import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { postEvent } from '../../services';
import { getUserId } from '../../services/userService';
import { theme } from '../../styles';
import { Button } from './Button';

type Props = {
  m?: string;
  id?: number;
  isMobile?: boolean;
};

const StyledComponent = styled.div<Props>`
  display: flex;
  gap: 8px;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  ${({ m }) =>
    m &&
    css`
      margin: ${m};
    `};
  ${theme.breakpoints.mobile} {
    gap: 4px;
    width: 100%;
    max-width: 344px;
    margin: 12px auto 0;
  }
  & > button {
    padding: 10;
    width: 240px;
    ${theme.breakpoints.mobile} {
      padding: 12.5px;
      width: calc(50% - 2px);
    }
  }
`;

const desktopButtonStyles = {
  radius: 12,
  fz: 20,
  fw: 400,
  lh: 28,
  ls: '-0.03em',
  ff: 'SB Sans Interface',
};

const mobileButtonStyles = {
  radius: 12,
  fz: 16,
  fw: 400,
  lh: 20,
  ls: undefined,
  ff: 'SB Sans Interface',
};

export const FooterButtonsWrapper = ({ m, id, isMobile }: Props) => {
  const { malachite } = theme.colors.border;
  const navigate = useNavigate();

  const user = getUserId();

  const nextDamage = () => navigate(id ? `/damage/${id + 1}` : '/damage/2');

  return (
    <StyledComponent m={m}>
      {id !== 3 && (
        <Button
          {...(isMobile
            ? { ...mobileButtonStyles }
            : { ...desktopButtonStyles })}
          borderColor={malachite}
          withHover
          onClick={() => {
            postEvent({ user, eventId: 5 }).catch(() => navigate('/error'));
            nextDamage();
          }}
        >
          Другие риски
        </Button>
      )}
      <Button
        {...(isMobile ? { ...mobileButtonStyles } : { ...desktopButtonStyles })}
        borderColor={malachite}
        withHover
        onClick={() => {
          postEvent({ user, eventId: 6 }).catch(() => navigate('/error'));
          navigate('/story/0');
        }}
      >
        Реальные истории
      </Button>
    </StyledComponent>
  );
};
