import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { Typography } from '../common/Typography';
import { WithBorder } from '../common/WithBorder';

type Props = {
  image: any;
  title: string;
  isMobile?: boolean;
  w?: number;
  onClick?: () => void;
};

const StyledComponent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-radius: 25px;
  padding-top: 23px;
  background: ${({ theme: { colors } }) => colors.gradient.button};
  &:hover {
    background: ${({ theme: { colors } }) => colors.gradient.buttonHover};
  }
  ${theme.breakpoints.mobile} {
    border-radius: 12px;
    padding-top: 0;
  }
`;

export const ChoiceCard = ({ title, image, onClick, isMobile, w }: Props) => {
  const {
    text: { lightGreen },
  } = theme.colors;

  return isMobile ? (
    <WithBorder p="4px" borderRadius={16} onClick={onClick} w="157px" h={129}>
      <StyledComponent>
        <img src={image} alt="" width={w ?? 66} height={58} />
        <Typography

          fw={400}
          fz={16}

          center
          color={lightGreen}
        >
          {title}
        </Typography>
      </StyledComponent>
    </WithBorder>
  ) : (
    <WithBorder
        shadow='0px 0px 2px #11FFD5'
      p="12px 13px"
      borderRadius={28}
      onClick={onClick}
      w="238.55px"
      h={256}
    >
      <StyledComponent>
        <img src={image} alt="" width={114} height={114} />
        <Typography
          p="0 0px 0 0px"
          fw={400}
          fz={28}
          lh={40}
          width={232}
          ls="-0.02em"
          center
          color={lightGreen}
        >
          {title}
        </Typography>
      </StyledComponent>
    </WithBorder>
  );
};
