import { useNavigate } from 'react-router-dom';

import { useState } from 'react';

import styled from 'styled-components';

import { theme } from '../styles';
import orangeCircleImage from '../assets/images/stand-circle/stand-circle-orange.png';
import { getSpotImageData, postEvent } from '../services';
import { useMobile } from '../hooks';
import orangeMobileCircleImg from '../assets/images/stand-circle/stand-mobile-circle-orange.png';
import spritesheet from '../assets/images/character/spritesheet-character-three.png';
import { getUserId } from '../services/userService';

import { PageTitle } from '../components/layout/PageTitle';
import { PageWrapper } from '../components/layout/PageWrapper';

import { Button } from '../components/buttons/Button';
import { AccidentInput } from '../components/accident/AccidentInput';
import { StandMobile } from '../components/common/StandMobile';
import { Typography } from '../components/common/Typography';
import { WithBorder } from '../components/common/WithBorder';
import { Stand } from '../components/common/Stand';
import { Column } from '../components/common/Column';

const Disclaimer = styled.div`
  position: absolute;
  bottom: 26px;
  left: 50%;
  font-family: 'SB Sans Interface';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.35);
  transform: translate(-50%);
  ${theme.breakpoints.mobile} {
    font-size: 10px;
    line-height: 10px;
    width: 100%;
    max-width: min(330px, 100%);
    bottom: 10px;
    text-align: center;
  }
`;

export const AccidentPage = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();

  const user = getUserId();

  const {
    text: { yellow, cream },
    border: { lightYellow },
  } = theme.colors;

  const [isValid, setValid] = useState(false);
  const [isTouched, setTouched] = useState(false);

  return (
    <PageWrapper
      p={isMobile ? '0 25px 56px 25px' : '0 40px 36px'}
      isMobile={isMobile}
      column={isMobile}
      backgroundColor="orange"
      hideDiscount
    >
      {isMobile ? (
        <>
          <StandMobile
            img={spritesheet}
            circleImage={orangeMobileCircleImg}
            backgroundColor="orange"
          />
          <PageTitle m="-8px 0 4px" fz={24} lh={28} mw={300} fw={700}>
            Произошел пожар!
          </PageTitle>
          <Typography
            m="0 0 21px 0"
            fz={16}
            lh={24}
            fw={400}
            center
            color={yellow}
            ls="-0.02em"
            mw={300}
          >
            Повреждение недвижимости 50%
          </Typography>
          <WithBorder
            borderRadius={28}
            p="18px 19px 22px"
            borderColor={lightYellow}
          >
            <Typography
              fw={400}
              fz={16}
              lh={22}
              center
              m="0 0 12px 0"
              color={cream}
            >
              Как вы думаете, сколько можете потерять?
            </Typography>
            <AccidentInput
              mb={11}
              isMobile
              hint="Могу потерять"
              placeholder="0 ₽"
              setValid={setValid}
              isValid={isValid || !isTouched}
            />
            <WithBorder borderRadius={16} p="3px">
              <Button
                onClick={() => {
                  if (isValid) {
                    postEvent({ user, eventId: 4 }).catch(() =>
                      navigate('/error')
                    );
                    navigate('/damage/1');
                  } else setTouched(true);
                }}
                radius={12}
                p="6px 40px"
                fz={16}
                lh={24}
                fw={500}
                ls="-0.02em"
                mw="100%"
                withGradientBg
              >
                Узнать возможные потери
              </Button>
            </WithBorder>
          </WithBorder>
        </>
      ) : (
        <>
          <Stand
            person={getSpotImageData()[2]}
            circleImage={orangeCircleImage}
            withBackBtn
          />
          <Column mw={765} p="min(204px,10.62vw) 0 0">
            <Typography
              mw={682}
              lh={64}
              fz={60}
              fw={700}
              center
              color="#ffffff"
              m="0 0 36px 0"
            >
              Произошел пожар!
            </Typography>
            <Typography
              mw={682}
              lh={48}
              fz={40}
              fw={400}
              center
              color={yellow}
              ls="-0.02em"
              m="0 0 165px 0"
            >
              Повреждение недвижимости 50%
            </Typography>
            <WithBorder
              borderRadius={28}
              p="38px 45px 47px 45px"
              borderColor={lightYellow}
              w="100%"
              mw="642px"
              m="0 0 33px"
              h={398}
            >
              <Typography
                mw={552}
                fw={500}
                lh={30}
                fz={24}
                center
                m="0 0 39px 0"
                ls="-0.02em"
                color="#ffffff"
              >
                Как вы думаете, сколько можете потерять?
              </Typography>
              <AccidentInput
                mb={16}
                hint="Могу потерять"
                placeholder="0 ₽"
                setValid={setValid}
                isValid={isValid || !isTouched}
              />
              <WithBorder borderRadius={31} mw="363px" m="0 auto">
                <Button
                  onClick={() => {
                    if (isValid) {
                      postEvent({ user, eventId: 4 }).catch(() =>
                        navigate('/error')
                      );
                      navigate('/damage/1');
                    } else setTouched(true);
                  }}
                  radius={24}
                  p="20px 18px"
                  fz={24}
                  lh={40}
                  fw={500}
                  ls="-0.02em"
                  h={116}
                  withGradientBg
                >
                  Узнать возможные потери
                </Button>
              </WithBorder>
            </WithBorder>
          </Column>
        </>
      )}
      <Disclaimer>
        Информация приведена в целях иллюстрации возможных событий на объекте
        недвижимости
      </Disclaimer>
    </PageWrapper>
  );
};
