import styled from 'styled-components';
import { theme } from '../../styles';

const StyledComponent = styled.div<{ percents: number }>`
  width: 110px;
  height: 23px;
  background: ${({ theme: t }) => t.colors.background.indicator};
  border-radius: 6px;
  overflow: hidden;
  ${theme.breakpoints.mobile} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 14px;
    width: 90px;
  }
  div {
    border-radius: 6px;
    height: 100%;
    width: ${({ percents }) => `${percents}%`};
    background: ${({ theme: t }) => t.colors.gradient.indicator};
  }
`;

export const ProgressIndicator = ({ percents }: { percents: number }) => (
  <StyledComponent percents={percents}>
    <div />
  </StyledComponent>
);
