import styled, { css } from 'styled-components';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  p?: string;
  t?: string;
  borderColor?: string;
  radius?: number;
  center?: boolean;
  hideFilter?: boolean;
  shadow?: string;
  mh?: string;
  mw?: string;
  className?: string;
};

const Styled = styled.div<Props>`
  position: relative;
  ${({ t }) =>
    t &&
    css`
      position: absolute;
      top: ${t};
      left: 50%;
      transform: translateX(-50%);
    `};
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid
    ${({ borderColor }) =>
      borderColor ? `${borderColor}` : 'rgba(255, 255, 255, 0.4)'};
  box-shadow: ${({ shadow }) =>
    shadow ? `${shadow}` : '0px 4px 44px rgba(17, 44, 42, 0.2)'};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : '50px')};
  width: ${({ mw }) => (mw ? `${mw}` : '100%')};
  padding: ${({ p }) => (p ? `${p}` : '8px')};
  ${({ hideFilter, radius }) =>
    !hideFilter &&
    css`
      ::before {
        overflow: hidden;
        border-radius: ${radius ? `${radius}px` : '50px'};
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        backdrop-filter: blur(26px);
      }
    `};
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `};
  z-index: 3;
  ${({ mw }) =>
    mw &&
    css`
      max-width: ${mw};
    `};
  ${({ mh }) =>
    mh &&
    css`
      min-height: ${mh};
    `};
`;

export const WithBlur = (props: Props) => <Styled {...props} />;
