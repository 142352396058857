import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { QuizData, CalculateDto } from '../models';
import {
  calculateCost,
  getQuizQuestions,
  getSpotImageData,
  postEvent,
} from '../services';
import { theme } from '../styles';

import { useMobile } from '../hooks';
import spritesheet1 from '../assets/images/character/spritesheet-character-one.png';
import spritesheet2 from '../assets/images/character/spritesheet-character-four.png';
import { getUserId } from '../services/userService';
import { PageTitle } from '../components/layout/PageTitle';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StandMobile } from '../components/common/StandMobile';
import { Typography } from '../components/common/Typography';
import { BlurredDiscountBlock } from '../components/quiz/BlurredDiscountBlock';
import { ProgressIndicator } from '../components/quiz/ProgressIndicator';
import { Stand } from '../components/common/Stand';
import { Column } from '../components/common/Column';
import { Select } from '../components/quiz/Select';
import { IdsHistoryContext } from '../context/IdsHistory';
import { InsuranceContext } from '../context/Promo';
import { QuizContext } from '../context/Quiz';

function importAll(r: any) {
  return r.keys().map(r);
}
const pigSounds = importAll(
  require.context('../assets/sounds/Piggy-sounds', false, /\.mp3$/)
);

export const QuizPage = () => {
  const id = Number(useParams().id) ?? 0;
  const navigate = useNavigate();
  const isMobile = useMobile();

  const { idsHistoryContext, setIdsHistoryContext } =
    useContext(IdsHistoryContext);
  const { insuranceContext, setInsuranceContext } =
    useContext(InsuranceContext);
  const { quizContext, setQuizContext } = useContext(QuizContext);

  const { lightGreen } = theme.colors.text;

  const [quizData, setQuizData] = useState<QuizData[]>(getQuizQuestions);
  const [pigIndex, setPigIndex] = useState<null | number>(null);

const user = getUserId();

  const percents = Math.round(
    id === 10 ? 100 : (1 + idsHistoryContext.length) / 0.11
  );

  const randomBoolean = useMemo(() => !!id && Math.random() < 0.5, [id]);

  const playPigSound = () => {
    const randIdx = Math.floor(Math.random() * 3);
    const audio = new Audio(pigSounds.slice(0, 3)[randIdx]);
    audio.play();
  };

  useEffect(() => {
    setIdsHistoryContext((prev) =>
      [...prev, id].filter(
        (el, index, arr) => arr.indexOf(el) === index && el <= id
      )
    );
  }, [id, setIdsHistoryContext]);

  useEffect(() => {
    const selectedAnswer = quizData[id].selected;
    const isRentalBusiness = quizContext.businessType === 3;
    const isPropertyOwned = quizContext.isRented === 0;

    const calculate = () =>
      calculateCost(quizContext)
        .then(({ data }) => {
          setInsuranceContext((prev) => ({
            ...prev,
            discountSuperpromo: Math.round(data[8]),
            discountPromo: Math.round(data[7]),
            insuranceFullCost: Math.round(data[6]),
            assets: data[0],
            finance: data[1],
            responsibility: data[2],
            staff: data[3],
            countOfEmployees:
              quizContext.employeesCount === 0 ? 1 : quizContext.employeesCount,
            additionalRisks: data[5],
            insuranceUrl: data[9],
          }));
          postEvent({ user, eventId: 3 }).catch(() => navigate('/error'));
          navigate('/accident');
        })
        .catch(() => navigate('/error'));

    if (
      id !== 10 &&
      !(id === 9 && selectedAnswer === 'работаю один') &&
      pigSounds.length
    ) {
      setPigIndex(Math.random());
      playPigSound();
    }

    if (id === 0 && selectedAnswer) {
      navigate(`/quiz/${id + 1}`);
    }
    if (id === 1 && selectedAnswer) {
      navigate(`/quiz/${id + (isRentalBusiness ? 2 : 1)}`);
    }
    if (id === 2 && selectedAnswer) {
      navigate(`/quiz/${id + 1}`);
    }
    if (id === 3 && selectedAnswer) {
      navigate(`/quiz/${id + 1}`);
    }
    if (id === 4) {
      if (selectedAnswer === 'Да') {
        navigate(`/quiz/${id + 1}`);
      }
      if (selectedAnswer === 'Нет') {
        if (isRentalBusiness) {
          navigate(`/quiz/${id + 4}`);
        }
        if (!isRentalBusiness) {
          navigate(`/quiz/${id + (isPropertyOwned ? 3 : 2)}`);
        }
      }
    }
    if (id === 5 && selectedAnswer) {
      if (isRentalBusiness) navigate(`/quiz/${id + 3}`);
      if (!isRentalBusiness) {
        navigate(`/quiz/${id + (isPropertyOwned ? 2 : 1)}`);
      }
    }
    if (id === 6 && selectedAnswer) {
      navigate(`/quiz/${id + 1}`);
    }
    if (id === 7 && selectedAnswer) {
      navigate(`/quiz/${id + 2}`);
    }
    if (id === 8 && selectedAnswer) {
      navigate(`/quiz/${id + 1}`);
    }
    if (id === 9 && selectedAnswer) {
      if (selectedAnswer === 'работаю один') {
        setQuizContext((prev) => ({ ...prev, employeeSalary: 0 }));
        calculate();
      } else {
        navigate(`/quiz/${id + 1}`);
      }
    }

    if (id === 10 && selectedAnswer) {
      calculate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizData]);

  const handleSelect = (
    index: number,
    selectValue: { text: string; value: number }
  ) => {
    setQuizData((prev) =>
      prev.map((v, i) =>
        i === index ? { ...v, selected: selectValue.text } : v
      )
    );
    setQuizContext(
      (prev) =>
        ({ ...prev, [quizData[id].field]: selectValue.value } as CalculateDto)
    );
  };

  return (
    <PageWrapper
      p={isMobile ? '0 19px 36px' : '0 40px 36px'}
      isMobile={isMobile}
      column={isMobile}
      indicatorPercents={id === 10 ? 100 : percents}
      backgroundColor="green"
      pigIndexDefault={pigIndex}
    >
      {isMobile ? (
        <>
          <StandMobile
            img={randomBoolean ? spritesheet1 : spritesheet2}
            backgroundColor="green"
          />
          <PageTitle
            m={quizData[id].subTitle ? '-8px 0 4px' : '-8px 0 0'}
            fz={20}
            lh={26}
            mw={320}
            fw={700}
          >
            {quizData[id].title}
          </PageTitle>
          {quizData[id].subTitle && (
            <Typography
              fz={16}
              lh={24}
              fw={400}
              center
              color={lightGreen}
              ls="-0.02em"
              mw={250}
            >
              {quizData[id].subTitle}
            </Typography>
          )}
          <Select
            quizData={quizData[id]}
            onSelect={(v) => handleSelect(id, v)}
            isMobile={isMobile}
          />
        </>
      ) : (
        <>
          <Stand person={getSpotImageData({ randomBoolean })[1]} withBackBtn>
            <BlurredDiscountBlock
              percents={Math.round(
                percents * insuranceContext.discountMaxPercents
              )}
              pigIndexDefault={pigIndex}
            />
          </Stand>
          <Column p="min(89px,4.63vw) 0 0">
            <ProgressIndicator percents={id === 10 ? 100 : percents} />
            <PageTitle
              m={quizData[id].subTitle ? '20px 0 24px' : '20px 0 136px'}
              mw={765}
              fw={700}
            >
              {quizData[id].title}
            </PageTitle>
            {quizData[id].subTitle && (
              <Typography
                m="0 0 75px 0"
                fz={24}
                lh={32}
                fw={400}
                center
                color={lightGreen}
                ls="-0.02em"
                mw={510}
              >
                {quizData[id].subTitle}
              </Typography>
            )}
            <Select
              quizData={quizData[id]}
              onSelect={(v) => handleSelect(id, v)}
              isMobile={isMobile}
            />
          </Column>
        </>
      )}
    </PageWrapper>
  );
};
