import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Typography } from '../common/Typography';
import { WithBorder } from '../common/WithBorder';
import { QuizData } from '../../models';
import { Button } from '../buttons/Button';
import { theme } from '../../styles';

const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  ${theme.breakpoints.mobile} {
    gap: 8px;
    width: 100%;
    max-width: min(500px, 100%);
  }
`;

export const Select = ({
  quizData,
  onSelect,
  isMobile,
}: {
  quizData: QuizData;
  onSelect: (value: { text: string; value: number }) => void;
  isMobile: boolean;
}) => (
  <>
    <Typography
      fw={isMobile ? 600 : 500}
      fz={isMobile ? 16 : 60}
      lh={isMobile ? 24 : 64}
      mw={
        // eslint-disable-next-line no-nested-ternary
        isMobile
          ? 250
          : [
              'Какая стоимость оборудования и товара на объекте?',
              'Объект недвижимости в собственности?',
            ].includes(quizData.question)
          ? 986
          : 644
      }
      ls={isMobile ? undefined : '-0.02em'}
      m={isMobile ? '36px auto 12px' : '0 auto 32px'}
      color="#ffffff"
      center
    >
      {quizData.question}
    </Typography>
    <ItemsWrapper>
      {quizData.answers.map((v) => (
        <WithBorder
          p={isMobile ? '3px' : '8px'}
          borderRadius={isMobile ? 16 : 31}
          key={uuidv4()}
          w={isMobile ? 'calc((100% - 8px) / 2)' : undefined}
          h={isMobile ? 60 : undefined}
        >
          <Button
            onClick={() => onSelect(v)}
            withGradientBg
            mw={isMobile ? '100%' : '311px'}
            h={isMobile ? undefined : 120}
            radius={isMobile ? 12 : 24}
            fw={500}
            lh={isMobile ? 14 : 20}
            p={isMobile ? '1px' : undefined}
          >
            {v.text}
          </Button>
        </WithBorder>
      ))}
    </ItemsWrapper>
  </>
);
