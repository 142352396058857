export const Star = () => (
  <svg
    width="30"
    height="27"
    viewBox="0 0 30 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0409 2.6041C14.3482 1.98151 14.5019 1.67022 14.7105 1.57076C14.892 1.48423 15.1028 1.48423 15.2843 1.57076C15.4929 1.67022 15.6466 1.98151 15.9539 2.6041L18.8695 8.51072C18.9602 8.69452 19.0056 8.78642 19.0719 8.85777C19.1306 8.92095 19.201 8.97214 19.2792 9.0085C19.3675 9.04957 19.4689 9.06439 19.6717 9.09404L26.1934 10.0473C26.8801 10.1477 27.2235 10.1979 27.3824 10.3656C27.5207 10.5115 27.5857 10.7121 27.5594 10.9114C27.5292 11.1404 27.2806 11.3826 26.7834 11.8668L22.066 16.4616C21.9189 16.6048 21.8454 16.6764 21.798 16.7616C21.756 16.8371 21.729 16.92 21.7186 17.0057C21.7069 17.1025 21.7242 17.2037 21.7589 17.406L22.872 23.8958C22.9894 24.5803 23.0481 24.9226 22.9378 25.1257C22.8418 25.3024 22.6712 25.4263 22.4735 25.463C22.2462 25.5051 21.9389 25.3435 21.3242 25.0202L15.4939 21.9541C15.3122 21.8586 15.2214 21.8108 15.1257 21.7921C15.041 21.7754 14.9538 21.7754 14.8691 21.7921C14.7734 21.8108 14.6826 21.8586 14.5009 21.9541L8.67063 25.0202C8.05595 25.3435 7.74861 25.5051 7.52136 25.463C7.32364 25.4263 7.15303 25.3024 7.05705 25.1257C6.94673 24.9226 7.00543 24.5803 7.12283 23.8958L8.23591 17.406C8.27061 17.2037 8.28796 17.1025 8.27622 17.0057C8.26583 16.92 8.23888 16.8371 8.19687 16.7616C8.14943 16.6764 8.0759 16.6048 7.92884 16.4616L3.21148 11.8668C2.71428 11.3826 2.46568 11.1404 2.43543 10.9114C2.40911 10.7121 2.47413 10.5115 2.6124 10.3656C2.77131 10.1979 3.11469 10.1477 3.80145 10.0473L10.3231 9.09404C10.526 9.06439 10.6274 9.04957 10.7157 9.0085C10.7939 8.97214 10.8643 8.92095 10.923 8.85777C10.9893 8.78642 11.0346 8.69452 11.1254 8.51072L14.0409 2.6041Z"
      stroke="#FFB800"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
