export const CircleChecked = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.3307 13.7803V15.007C28.3291 17.8822 27.3981 20.6799 25.6765 22.9827C23.955 25.2856 21.5351 26.9703 18.7779 27.7855C16.0206 28.6007 13.0737 28.5028 10.3767 27.5064C7.67964 26.51 5.37694 24.6684 3.81201 22.2564C2.24708 19.8444 1.50378 16.9911 1.69296 14.1221C1.88215 11.2531 2.99367 8.52208 4.86177 6.33641C6.72987 4.15074 9.25444 2.62749 12.059 1.99384C14.8635 1.36019 17.7978 1.6501 20.4241 2.82031M28.3307 4.33268L14.9974 17.6793L10.9974 13.6793"
      stroke="#21A19A"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
