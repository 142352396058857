import { createContext, Dispatch, SetStateAction } from 'react';
import { CalculateDto } from '../models/quiz';

export const quizDefault = { calculationStep: 1 } as CalculateDto;

export const QuizContext = createContext<{
  quizContext: CalculateDto;
  setQuizContext: Dispatch<SetStateAction<CalculateDto>>;
}>({
  quizContext: quizDefault,
  setQuizContext: () => null,
});
