import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';


import { useMobile } from '../hooks';
import { CalculateDto } from '../models';
import { getFacilityCards, getSpotImageData } from '../services';
import spritesheet1 from '../assets/images/character/spritesheet-character-one.png';
import spritesheet2 from '../assets/images/character/spritesheet-character-four.png';
import { PageTitle } from '../components/layout/PageTitle';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StandMobile } from '../components/common/StandMobile';
import { ChoiceCard } from '../components/cards/ChoiceCard';
import { ChoiceCardsContainer } from '../components/cards/ChoiceCardsContainer';
import { Stand } from '../components/common/Stand';
import { BlurredDiscountBlock } from '../components/quiz/BlurredDiscountBlock';
import { ProgressIndicator } from '../components/quiz/ProgressIndicator';
import { Column } from '../components/common/Column';
import { QuizContext } from '../context/Quiz';
import { InsuranceContext } from '../context/Promo';

function importAll(r: any) {
  return r.keys().map(r);
}
const pigSounds = importAll(
  require.context('../assets/sounds/Piggy-sounds', false, /\.mp3$/)
);

export const FacilitySelectPage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();
  const { setQuizContext } = useContext(QuizContext);
  const { insuranceContext } = useContext(InsuranceContext);
  const ref = React.useRef<{ triggerPigHint: () => void }>(null);

  const percents = insuranceContext.discountMaxPercents / 0.11;
  const randomBoolean = useMemo(() => Math.random() < 0.5, []);

  // const { files } = useContext(PrefetchFilesContext);

  useEffect(() => {
    const randIdx = Math.floor(Math.random() * 3);
    const audio = new Audio(pigSounds.slice(0, 3)[randIdx]);
    audio.play();
  }, [pigSounds]);

  useEffect(() => {
    ref.current?.triggerPigHint();
  }, []);

  return (
    <PageWrapper
      p={isMobile ? '0 19px 36px' : '0 40px 36px'}
      isMobile={isMobile}
      column={isMobile}
      indicatorPercents={percents / insuranceContext.discountMaxPercents}
      backgroundColor="green"
      pigIndexDefault={1}
      pigHintRef={ref}
    >
      {isMobile ? (
        <>
          <StandMobile
            img={randomBoolean ? spritesheet1 : spritesheet2}
            backgroundColor="green"
          />
          <PageTitle m="-8px 0 18px" fz={24} lh={28} mw={322} fw={700}>
            Выберите тип помещения, где осуществляется ваша деятельность
          </PageTitle>
          <ChoiceCardsContainer>
            {getFacilityCards({ isMobile: true }).map((c) => (
              <ChoiceCard
                key={c.value}
                title={c.title}
                image={c.image}
                isMobile
                onClick={() => {
                  setQuizContext(
                    (prev) => ({ ...prev, roomType: c.value } as CalculateDto)
                  );
                  navigate('/quiz/0');
                }}
              />
            ))}
          </ChoiceCardsContainer>
        </>
      ) : (
        <>
          <Stand person={getSpotImageData({ randomBoolean })[1]} withBackBtn>
            <BlurredDiscountBlock
              percents={Math.round(percents)}
              pigIndexDefault={1}
            />
          </Stand>
          <Column mw={877} p="min(89px,4.63vw) 0 0">

            <ProgressIndicator
              percents={percents / insuranceContext.discountMaxPercents}
            />
            <PageTitle m="27px 0 0 0" lh={64} fw={300} color='white' fz={40}>
              Для оценки рисков,<br/> расскажите о своём бизнесе
            </PageTitle>
            <PageTitle m="27px 0 158px 0" lh={48} fz={60} fw={400}>
              Выберите тип помещения, где осуществляется ваша деятельность
            </PageTitle>
            <ChoiceCardsContainer>
              {getFacilityCards({ isMobile: false }).map((c) => (
                <ChoiceCard
                  key={c.value}
                  title={c.title}
                  image={c.image}
                  onClick={() => {
                    setQuizContext(
                      (prev) => ({ ...prev, roomType: c.value } as CalculateDto)
                    );
                    navigate('/quiz/0');
                  }}
                />
              ))}
            </ChoiceCardsContainer>
          </Column>
        </>
      )}
    </PageWrapper>
  );
};
