import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import SwipeableViews from 'react-swipeable-views';

import { getSpotImageData, getInsuranceHints, postEvent } from '../services';
import { theme } from '../styles';
import { InsuranceContext } from '../context/Promo';
import { useMobile } from '../hooks';
import { getUserId } from '../services/userService';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StandMobile } from '../components/common/StandMobile';
import { InsuranceHintCard } from '../components/cards/InsuranceHintCard';
import { Stand } from '../components/common/Stand';

import { Column } from '../components/common/Column';
import { Typography } from '../components/common/Typography';
import { Bubble } from '../components/insurance/Bubble';
import { InsuranceInfo } from '../components/insurance/InsuranceInfo';
import { Button } from '../components/buttons/Button';
import { FooterButtonsWrapper } from '../components/buttons/FooterButtonsWrapper';

const HintsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 14px;
  justify-content: center;
  max-width: 836px;
  ${theme.breakpoints.mobile} {
    gap: 6px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 17px;
`;

const InsuranceLink = styled.a`
  font-family: 'SB Sans Interface';
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 2px;
  letter-spacing: -0.3px;
  cursor: pointer;
  margin-top: 10px;
  color: ${theme.colors.text.malachite};
  border-bottom: 1px solid ${theme.colors.text.malachite};
  margin-bottom: 28px;
  ${theme.breakpoints.mobile} {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 43px;
  }
`;

const AboutLink = styled.div`
  font-family: 'SB Sans Interface';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 2px;
  cursor: pointer;
  margin-bottom: 17px;
  z-index: 1;
  color: ${theme.colors.text.malachite};
  border-bottom: 1px solid ${theme.colors.text.malachite};
`;

const mobileButtonStyles = {
  radius: 16,
  p: '19px',
  fz: 16,
  lh: 20,
  mw: undefined,
  ls: undefined,
};

const Disclaimer = styled.div`
  position: absolute;
  bottom: 38px;
  left: 190px;
  max-width: min(904px, 47%);
  font-family: 'SB Sans Interface';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.35);
  a {
    font-family: 'SB Sans Interface';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.35);
    font-size: 12px;
    text-decoration: underline;
  }
  @media (max-width: 1300px) {
    max-width: min(774px, 35%);
  }
  @media (max-width: 1660px) {
    max-width: min(774px, 37%);
  }
  ${theme.breakpoints.mobile} {
    font-size: 10px;
    line-height: 10px;
    width: 100%;
    max-width: min(600px, 100%);
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    padding: 0 8px;
    text-align: center;
  }
`;

const Hints = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
  padding-bottom: 50px;
  min-height: 614px;
  align-items: flex-start;
  & > div {
    overflow: visible !important;
  }
  .react-swipeable-view-container {
    max-width: max(278px, 77.22vw);
    & > div {
      &:not(:last-child) {
        padding-right: 6px;
      }
    }
  }
  ${theme.breakpoints.mobile} {
    align-self: flex-start;
    padding-bottom: 8px;
    min-height: unset;
    margin-bottom: auto;
  }
`;

const SwipeableComp = ({
  data,
  getSumByTitle,
}: {
  data: typeof getInsuranceHints | null;
  getSumByTitle: (title: string) => number;
}) =>
  data ? (
    <SwipeableViews enableMouseEvents>
      {data.map((hint) => (
        <InsuranceHintCard
          sum={getSumByTitle(hint.title)}
          {...hint}
          key={uuidv4()}
        />
      ))}
    </SwipeableViews>
  ) : (
    <div />
  );

export const InsurancePage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();
  const { insuranceContext } = useContext(InsuranceContext);

const user = getUserId();
  const {
    text: { malachite, lightGreen },
    border: { malachite: malachiteBorder },
  } = theme.colors;

  const getSumByTitle = (title: string) => {
    if (title === 'Защита финансов') return insuranceContext.finance;
    if (title === 'Защита от претензий') return insuranceContext.responsibility;
    if (title === 'Защита сотрудников') return insuranceContext.staff;
    return insuranceContext.assets;
  };

  const getPromocode = (): string =>
    insuranceContext.discountMaxPercents === 0.27
      ? 'GAMIFICATION40'
      : 'SUPERPROMO';
  const getPromocodeUrl = () => {
    const expectedPrice = (
      insuranceContext.discountMaxPercents === 0.27
        ? insuranceContext.discountPromo
        : insuranceContext.discountSuperpromo
    ).toFixed(2);
    return `${
      insuranceContext.insuranceUrl
    }&expectedPrice=${expectedPrice}&promoCode=${getPromocode()}`;
  };

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <PageWrapper
      p={isMobile ? `0 8px ${isSafari ? 300 : 250}px 8px` : '0 40px 36px'}
      isMobile={isMobile}
      column={isMobile}
      backgroundColor="green"
    >
      {isMobile ? (
        <>
          <StandMobile onlyGradient backgroundColor="green" />
          <Typography
            lh={26}
            fz={20}
            mw={300}
            fw={700}
            center
            color="#ffffff"
            m="70px 0 8px"
          >
            Вы бы сохранили свои&nbsp;деньги
          </Typography>
          <Typography
            fz={16}
            lh={24}
            fw={400}
            center
            ls="-0.02em"
            color={lightGreen}
            m="0 0 15px"
          >
            Что входит в страховое покрытие
          </Typography>
          <Hints>
            <SwipeableComp
              data={getInsuranceHints}
              getSumByTitle={getSumByTitle}
            />
          </Hints>
          <InsuranceLink href="https://www.sberbank.com/common/img/uploaded/mmb/insurance/360/usloviya_strakhovaniya.pdf">
            Условия страхования
          </InsuranceLink>
          <InsuranceInfo data={insuranceContext} isMobile={isMobile} />
          <AboutLink
            onClick={() => {
              postEvent({ user, eventId: 7 }).catch(() => navigate('/error'));
              navigate('/about');
            }}
          >
            Подробнее о продукте
          </AboutLink>
          <Button
            onClick={() => {
              postEvent({ user, eventId: 9 }).catch(() => navigate('/error'));
              const link = document.createElement('a');
              link.href = getPromocodeUrl();
              document.body.appendChild(link);
              link.click();
            }}
            {...mobileButtonStyles}
            fw={500}
            m="30px 0 4px"
            withGradientBg
            borderColor={malachite}
          >
            Забрать скидку и застраховаться
          </Button>
          <Button
            onClick={() => navigate('/request-call')}
            {...mobileButtonStyles}
            borderColor={malachiteBorder}
          >
            Заказать звонок консультанта
          </Button>
          <FooterButtonsWrapper isMobile />
        </>
      ) : (
        <>
          <Stand person={getSpotImageData()[3]} withBackBtn>
            <InsuranceInfo data={insuranceContext} />
            <Bubble />
          </Stand>
          <Column p="min(132px,6.87vw) 0 0">
            <Typography
              mw={682}
              lh={64}
              fz={60}
              fw={700}
              center
              color="#ffffff"
              m="0 0 24px 20px"
            >
              Вы бы сохранили свои&nbsp;деньги
            </Typography>
            <Typography
              lh={48}
              fw={500}
              fz={40}
              mw={682}
              ls="-0.02em"
              center
              color={malachiteBorder}
              m="0 0 40px 0"
            >
              Что входит в страховое покрытие
            </Typography>
            <HintsWrapper>
              {getInsuranceHints.map((hint) => (
                <InsuranceHintCard
                  {...hint}
                  numberOfEmployees={insuranceContext.countOfEmployees}
                  key={uuidv4()}
                  w={411}
                  sum={getSumByTitle(hint.title)}
                />
              ))}
            </HintsWrapper>
            <InsuranceLink href="https://www.sberbank.com/common/img/uploaded/mmb/insurance/360/usloviya_strakhovaniya.pdf">
              Условия страхования
            </InsuranceLink>
            <ButtonsWrapper>
              <Button
                  onClick={() => {
                    postEvent({ user, eventId: 9 }).catch(() =>
                      navigate('/error')
                    );
                  const link = document.createElement('a');
                  link.href = getPromocodeUrl();
                  document.body.appendChild(link);
                  link.click();
                }}
                radius={24}
                p="32px 16px"
                fz={20}
                lh={30}
                fw={500}
                mw="260px"
                ls="-0.02em"
                withGradientBg
                borderColor={malachiteBorder}
              >
                Забрать скидку
                <br /> и застраховаться
              </Button>
              <Button
                onClick={() => navigate('/request-call')}
                radius={24}
                p="32px 16px"
                fz={20}
                lh={30}
                fw={500}
                mw="260px"
                ls="-0.02em"
                borderColor={malachiteBorder}
              >
                Заказать звонок консультанта
              </Button>
            </ButtonsWrapper>
            <FooterButtonsWrapper m="0 auto" />
          </Column>
        </>
      )}
      <Disclaimer>
        Информация приведена в целях иллюстрации работы страхования, не является
        публичной офертой.
        <br />
        Услуги по страхованию оказывает ООО СК «Сбербанк страхование», лицензии
        Банка России СИ № 4331, СЛ № 4331 выдана 10.06.2021 бессрочно. <br />
        ОГРН 1147746683479. Адрес: Москва, ул. Поклонная, д. 3, корп. 1, 1 этаж,
        помещение 3. Реклама. Рекламодатель ООО СК «Сбербанк страхование»{' '}
        <a href="https://www.sberbankins.ru" target="_blank" rel="noreferrer">
          www.sberbankins.ru
        </a>
        <br />
        Для получения точного расчета необходимо заполнить данные по объекту
        страхования{' '}
        <a
          href="https://www.sberbank.com/ru/s_m_business/insurance/360"
          target="_blank"
          rel="noreferrer"
        >
          https://www.sberbank.com/ru/s_m_business/insurance/360
        </a>
        <br />
        «Скидка» - снижение стоимости страхового полиса по продукту «Защита
        360°» по сравнению со стандартной ценой Страховщика на сайте{' '}
        <a
          href="https://sberbankins.ru/upload/2023/pravila/pravila_zashita_360.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://sberbankins.ru/upload/2023/pravila/pravila_zashita_360.pdf
        </a>
        . Подробности об условиях страхования{' '}
        <a
          href="https://www.sberbank.com/ru/s_m_business/insurance/360"
          target="_blank"
          rel="noreferrer"
        >
          https://www.sberbank.com/ru/s_m_business/insurance/360
        </a>
      </Disclaimer>
    </PageWrapper>
  );
};
