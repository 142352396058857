import iconTwo from '../assets/images/icon/icon-two.png';
import iconFive from '../assets/images/icon/icon-five.png';
import iconFour from '../assets/images/icon/icon-four.png';
import iconSeven from '../assets/images/icon/icon-seven.png';

export const getInsuranceHints = [
  {
    icon: iconFive,
    title: 'Защита финансов',
    description:
      '​​​​​​Расходы на консультацию по результатам налоговой проверки, перерыв в деятельности.',
  },
  {
    icon: iconTwo,
    title: 'Защита от претензий',
    description: '​​​​​​Гражданская ответственность.',
  },
  {
    icon: iconFour,
    title: 'Защита сотрудников',
    description:
      'Травма; уход из жизни, инвалидность и больничный в результате несчастного случая; телемедицина и мед. сервисы.',
  },
  {
    icon: iconSeven,
    title: 'Защита активов',
    description:
      'Конструктив, внутренняя и внешняя отделка, инженерное оборудование, деньги в кассе, движимое имущество.',
  },
];
