import React, { Fragment } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { theme } from '../styles';
import { useMobile } from '../hooks';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StandMobile } from '../components/common/StandMobile';
import { Typography } from '../components/common/Typography';
import { BackBtn } from '../components/buttons/BackBtn';

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1015px;
  width: 100%;
  z-index: 1;
  ${theme.breakpoints.mobile} {
    max-width: 328px;
    align-items: center;
  }
`;

const AboutMobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 328px;
  width: 100%;
`;

const Line = styled.div`
  width: 100%;
  border-top: solid 1px rgba(255, 255, 255, 0.2);
  margin: 20px 0 0;
  ${theme.breakpoints.mobile} {
    margin: 20px 0 0;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const details = [
  'При расчете использовались максимальные суммы из выбранного вами диапазона стоимости недвижимости, отделки, товарно-материальных ценностей и оборудования',
  'Помещение выполнено из негорючих материалов (кирпич, бетон; отделка из декоративного кирпича/камня/панели/плиты)',
  'Если  здание не отдельно стоящее, то учитывается вред имуществу и перерыв в деятельности «соседей» со схожими параметрами помещений и бизнеса (предположительное количество до 5)',
  'Приостановка деятельности произойдет на период ремонта/восстановительных работ недвижимости, соответственно не будет получен ожидаемый доход, но все ваши обязательства сохраняются (кредит, зарплата и т.д.)',
];

export const DamageDetails: React.FC = () => {
  const isMobile = useMobile();

  return (
    <PageWrapper
      p={isMobile ? '21px 16px 60px' : '100px 40px 110px'}
      backgroundColor="green"
    >
      <StandMobile
        onlyGradient
        backgroundColor={isMobile ? 'green' : undefined}
      />
      <Flex>
        {isMobile ? (
          <AboutMobileHeader>
            <Typography
              fz={16}
              lh={24}
              color="#FFFFFF"
              fw={700}
              m="0 0 10px"
              mw={280}
            >
              Приведенный расчет убытка является ориентировочным и осуществлен
              по следующим параметрам
            </Typography>
            <BackBtn cross />
          </AboutMobileHeader>
        ) : (
          <Typography
            m="0 0 32px 0"
            color="#FFFFFF"
            fz={40}
            lh={46}
            ls="-0.02em"
            fw={700}
            center
            mw={1245}
          >
            Приведенный расчет убытка является ориентировочным и осуществлен
            по&nbsp;следующим&nbsp;параметрам
          </Typography>
        )}
        <ContentColumn>
          {details.map((detail, index) => (
            <Fragment key={uuidv4()}>
              {!(isMobile && index === 0) && <Line />}
              <Typography
                ff="SB Sans Interface"
                m={isMobile ? '20px 0' : '36px 0 20px'}
                color="rgba(242, 244, 247, 1)"
                fz={isMobile ? 16 : 30}
                lh={isMobile ? 22 : 36}
                ls="-0.02em"
                fw={400}
              >
                {detail}
              </Typography>
            </Fragment>
          ))}
        </ContentColumn>
      </Flex>

      {!isMobile && <BackBtn absolute />}
    </PageWrapper>
  );
};
