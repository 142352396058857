import styled, { css } from 'styled-components';
import { ReactNode, RefObject } from 'react';
import { MobileHeader } from './MobileHeader';
import { theme } from '../../styles';

type Props = {
  children: ReactNode;
  p: string;
  isMobile?: boolean;
  gap?: string;
  column?: boolean;
  backgroundColor?: 'green' | 'blue' | 'red' | 'orange' | 'default';
  hideDiscount?: boolean;
  hideBackBtn?: boolean;
  columnCenter?: boolean;
  indicatorPercents?: number;
  pigIndexDefault?: null | number;
  pigHintRef?: RefObject<{
    triggerPigHint: () => void;
  }>;
};

const Styled = styled.div<Props>`
  background: ${({ theme: t, backgroundColor, isMobile }) =>
    !isMobile && t.colors.gradient[backgroundColor ?? 'default']};
  ${theme.breakpoints.mobile} {
    z-index: -2;
  }
  .content {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    padding: ${({ p }) => p};
    justify-content: space-between;
    gap: ${({ gap }) => gap || '35px'};
    min-height: 100vh;
    overflow: hidden;
    ${({ column }) =>
      column &&
      css`
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    ${theme.breakpoints.mobile} {
      & > *:not(.stand) {
        z-index: 1;
      }
      background-color: ${theme.colors.background.page};
      gap: 0;
      flex-direction: column;
      justify-content: ${({ columnCenter }) =>
        columnCenter ? 'center' : 'flex-start'};
    }
  }
`;

export const PageWrapper = (props: Props) => {
  const {
    children,
    isMobile,
    hideDiscount,
    hideBackBtn,
    indicatorPercents,
    pigIndexDefault,
    pigHintRef,
  } = props;

  return (
    <Styled {...props}>
      {isMobile && (
        <MobileHeader
          hideBackBtn={hideBackBtn}
          hideDiscount={hideDiscount}
          indicatorPercents={indicatorPercents}
          pigIndexDefault={pigIndexDefault}
          pigHintRef={pigHintRef}
        />
      )}
      <main className="content">{children}</main>
    </Styled>
  );
};
