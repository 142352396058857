import aboveFirstFloor from '../assets/images/card-facility/card-above-first-floor.png';
import detachedBuilding from '../assets/images/card-facility/card-detached-building.png';
import firstFloor from '../assets/images/card-facility/card-first-floor.png';
import aboveFirstFloorSmall from '../assets/images/card-facility/card-above-first-floor-small.png';
import detachedBuildingSmall from '../assets/images/card-facility/card-detached-building-small.png';
import firstFloorSmall from '../assets/images/card-facility/card-first-floor-small.png';
import cardShop from '../assets/images/card-business/card-shop.png';
import cardManufacture from '../assets/images/card-business/card-manufacture.png';
import cardCafe from '../assets/images/card-business/card-cafe.png';
import cardRent from '../assets/images/card-business/card-rent.png';
import cardShopSmall from '../assets/images/card-business/card-shop-small.png';
import cardManufactureSmall from '../assets/images/card-business/card-manufacture-small.png';
import cardCafeSmall from '../assets/images/card-business/card-cafe-small.png';
import cardRentSmall from '../assets/images/card-business/card-rent-small.png';

export const getBusinessCards = ({
  isMobile,
}: {
  isMobile?: boolean;
}): {
  image: string;
  title: string;
  value: number;
}[] => [
  {
    image: isMobile ? cardShopSmall : cardShop,
    title: 'Розничная торговля',
    value: 0,
  },
  {
    image: isMobile ? cardManufactureSmall : cardManufacture,
    title: 'Производство',
    value: 1,
  },
  {
    image: isMobile ? cardCafeSmall : cardCafe,
    title: 'Кафе, рестораны',
    value: 2,
  },
  {
    image: isMobile ? cardRentSmall : cardRent,
    title: 'Арендный бизнес',
    value: 3,
  },
];

export const getFacilityCards = ({
  isMobile,
}: {
  isMobile?: boolean;
}): {
  image: string;
  title: string;
  value: number;
}[] => [
  {
    image: isMobile ? aboveFirstFloorSmall : aboveFirstFloor,
    title: 'Выше первого этажа',
    value: 2,
  },
  {
    image: isMobile ? detachedBuildingSmall : detachedBuilding,
    title: 'Отдельное здание',
    value: 0,
  },
  {
    image: isMobile ? firstFloorSmall : firstFloor,
    title: 'На первом этаже',
    value: 1,
  },
];
