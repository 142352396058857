import React from 'react';

import { useMobile } from '../hooks';
import { theme } from '../styles';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StandMobile } from '../components/common/StandMobile';
import { Typography } from '../components/common/Typography';

export const ErrorPage: React.FC = () => {
  const { lightGreen } = theme.colors.text;
  const isMobile = useMobile();

  return (
    <PageWrapper p="0" column columnCenter gap="0px" backgroundColor="green">
      {isMobile ? (
        <>
          <StandMobile onlyGradient backgroundColor="green" />
          <Typography
            m="0 0 8px 0"
            fz={24}
            lh={28}
            fw={700}
            center
            color="#FFFFFF"
          >
            Что-то пошло не так
          </Typography>
          <Typography
            fw={400}
            fz={16}
            lh={24}
            ls="-0.02em"
            center
            color={lightGreen}
          >
            Попробуйте перезагрузить страницу <br /> или вернуться на предыдущую
          </Typography>
        </>
      ) : (
        <>
          <Typography
            m="0 0 15px 0"
            mw={682}
            fz={40}
            lh={48}
            fw={700}
            ls="-0.02em"
            center
            color="#FFFFFF"
          >
            Что-то пошло не так
          </Typography>
          <Typography
            mw={510}
            fw={400}
            fz={24}
            lh={32}
            ls="-0.02em"
            center
            color={lightGreen}
          >
            Попробуйте перезагрузить страницу <br /> или вернуться на предыдущую
          </Typography>
        </>
      )}
    </PageWrapper>
  );
};
