import { useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { CalculateDto } from '../models';
import { GlobalStyles, theme } from '../styles';
import { Router } from './Router';
import { PrefetchFilesContext } from '../context/prefetchFiles';
import api from '../http/api';

import { InsuranceContext, InsuranceContextType } from '../context/Promo';
import { IdsHistoryContext } from '../context/IdsHistory';
import { QuizContext, quizDefault } from '../context/Quiz';

export const App = () => {
  const [quizContext, setQuizContext] = useState<CalculateDto>(quizDefault);
  const [insuranceContext, setInsuranceContext] =
    useState<InsuranceContextType>({
      discountMaxPercents: 0.27,
      discountPromo: 0,
      discountSuperpromo: 0,
      insuranceFullCost: 0,
      assets: 0,
      finance: 0,
      responsibility: 0,
      staff: 0,
      countOfEmployees: 0,
      additionalRisks: 0,
      insuranceUrl: '',
    });
  const [idsHistoryContext, setIdsHistoryContext] = useState<number[]>([]);
  const [files, setFiles] = useState<string[]>([]);

  const quizContextValue = useMemo(
    () => ({
      quizContext,
      setQuizContext,
    }),
    [quizContext]
  );

  const insuranceContextValue = useMemo(
    () => ({
      insuranceContext,
      setInsuranceContext,
    }),
    [insuranceContext]
  );

  const idsHistoryContextValue = useMemo(
    () => ({
      idsHistoryContext,
      setIdsHistoryContext,
    }),
    [idsHistoryContext]
  );

  const prefetchFilesValue = useMemo(
    () => ({
      files,
      setFiles,
    }),
    [files]
  );

  useEffect(() => {
    api
      .get('settings/getMaxDiscount')
      .then(({ data }) =>
        setInsuranceContext((prev) => ({
          ...prev,
          discountMaxPercents: data / 100,
        }))
      )
      .catch(() => {
        if (!window.location.pathname.includes('error'))
          window.location.href += 'error';
      });

  }, []);

  const prefetchFile = (url: string) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.addEventListener(
      'load',
      () => {
        if (xhr.status === 200) {
          const URL = window.URL || window.webkitURL;
          const blobUrl = URL.createObjectURL(xhr.response);

          setFiles((prev) => [...prev, blobUrl]);
        }
      },
      false
    );
    xhr.send();
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <QuizContext.Provider value={quizContextValue}>
        <InsuranceContext.Provider value={insuranceContextValue}>
          <IdsHistoryContext.Provider value={idsHistoryContextValue}>
            <PrefetchFilesContext.Provider value={prefetchFilesValue}>
              <Router />
            </PrefetchFilesContext.Provider>
          </IdsHistoryContext.Provider>
        </InsuranceContext.Provider>
      </QuizContext.Provider>
    </ThemeProvider>
  );
};
