import styled, { css } from 'styled-components';
import { ReactNode } from 'react';
import { theme } from '../../styles';

type Props = {
  children: ReactNode;
  mw?: number;
  p?: string;
};

const Styled = styled.div<Props>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  ${({ p }) =>
    p &&
    css`
      padding: ${p};
    `};
  ${({ mw }) =>
    mw &&
    css`
      max-width: ${mw}px;
      margin: 0 auto;
    `};
  ${theme.breakpoints.mobile} {
    padding: 0 16px;
  }
`;

export const Column = (props: Props) => <Styled {...props} />;
