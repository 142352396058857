import { EventDto } from '../models';

import api from '../http/api';

export const postEvent = (value: EventDto) =>
{
  if (value.user === 0) return new Promise(() => {});
  return api.post('stat/post', value, {
    headers: { 'Content-Type': 'application/json' },
  });
}

