import styled from 'styled-components';
import { Typography } from '../common/Typography';
import { WithBlur } from '../common/WithBlur';
import pig from '../../assets/images/pig.png';
import check from '../../assets/images/check.png';
import { getSplittedCost } from '../damage/DamageInfo';
import { theme } from '../../styles';
import { InsuranceContextType } from '../../context/Promo';

const {
  text: { insuranceCrossed },
} = theme.colors;

const StyledComponent = styled.div`
  display: flex;
  gap: 16px;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translateX(-50%);
  width: 103%;
  flex-wrap: wrap;
  justify-content: center;
  ${theme.breakpoints.mobile} {
    position: relative;
  }
  & > div {
    flex: 1 0 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  img {
    width: 170px;
    height: 170px;
    margin-bottom: 12px;
    &.check {
      width: 170px;
      object-fit: contain;
    }
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: auto;
  img {
    height: 57px;
    width: 57px;
    margin-right: 12px;
  }
`;

export const InsuranceInfo = (props: {
  data: InsuranceContextType;
  isMobile?: boolean;
}) => {
  const {
    data: {
      discountMaxPercents,
      insuranceFullCost,
      discountSuperpromo,
      discountPromo,
    },
    isMobile,
  } = props;

  return isMobile ? (
    <MobileWrapper>
      <img className="check" src={check} alt="check img" />
      <div>
        <Typography fz={20} lh={26} color="#ffffff">
          Страховка в год
        </Typography>
        <Typography fz={24} lh={30} ls="-0.02em" color="#ffffff">
          {getSplittedCost(
            Math.round(
              discountMaxPercents === 0.27 ? discountPromo : discountSuperpromo
            )
          )}{' '}
          ₽
        </Typography>
        <Typography fz={16} lh={24} color={insuranceCrossed} crossed>
          {getSplittedCost(Math.round(insuranceFullCost))} ₽
        </Typography>
      </div>
    </MobileWrapper>
  ) : (
    <StyledComponent>
      <WithBlur center p="22px 20px 35px" mw="375px">
        <img className="check" src={check} alt="check img" />
        <div>
          <Typography fz={24} lh={32} fw={500} color="#ffffff" m="0 0 8px">
            Страховка в год
          </Typography>
          <Typography fz={40} lh={46} fw={700} color="#ffffff">
            {getSplittedCost(
              Math.round(
                discountMaxPercents === 0.27 ? discountPromo : discountSuperpromo
              )
            )}{' '}
            ₽
          </Typography>
          <Typography fz={24} lh={26} color={insuranceCrossed} crossed>
            {getSplittedCost(Math.round(insuranceFullCost))} ₽
          </Typography>
        </div>
      </WithBlur>
      {!isMobile && (
        <WithBlur center p="22px 20px 41px" mw="375px">
          <img src={pig} alt="pig img" />
          <div>
            <Typography fz={24} lh={32} fw={500} color="#ffffff" m="0 0 8px">
              Заработанная скидка
            </Typography>
            <Typography fz={60} lh={66} fw={700} color="#ffffff">
              {discountMaxPercents * 100}%
            </Typography>
          </div>
        </WithBlur>
      )}
    </StyledComponent>
  );
};
