import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '../common/Typography';
import { theme } from '../../styles/theme';
import { RightPointer } from '../../assets/icons';
import { postEvent } from '../../services';
import { getUserId } from '../../services/userService';

const {
  text: { lightGreen },
  gradient: { bubble, bubbleOrange },
  shadow: { bubble: bubbleShadow },
} = theme.colors;

const StyledComponent = styled.div<{
  color?: 'default' | 'green' | 'blue' | 'red' | 'orange';
}>`
  max-width: 263px;
  width: 100%;
  height: 177px;
  position: absolute;
  bottom: min(85%, calc(43vw - 80px));
  left: min(83%, calc(35vw - 80px));
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  font-family: 'SB Sans Display';
  background: ${({ color }) => (color === 'orange' ? bubbleOrange : bubble)};
  box-shadow: ${bubbleShadow};
  visibility: ${({ color }) => (color === 'orange' ? 'visible' : 'hidden')};
  animation: show 0s 2s forwards;
  @keyframes show {
    to {
      visibility: visible;
    }
  }

  :before {
    content: '';
    width: 11%;
    height: 15%;
    position: absolute;
    bottom: 4%;
    left: -4%;
    border-radius: 50%;
    background: ${({ color }) => (color === 'orange' ? bubbleOrange : bubble)};
    box-shadow: ${bubbleShadow};
  }
  :after {
    content: '';
    width: 8%;
    height: 11%;
    position: absolute;
    bottom: 2%;
    left: -16%;
    border-radius: 50%;
    background: ${({ color }) => (color === 'orange' ? bubbleOrange : bubble)};
    box-shadow: ${bubbleShadow};
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Bubble = ({
  color,
}: {
  color?: 'default' | 'green' | 'blue' | 'red' | 'orange';
}) => {
  const navigate = useNavigate();
  const user = getUserId();

  return (
    <StyledComponent color={color}>
      <Typography
        m="0 0 9px 0"
        fz={20}
        lh={28}
        center
        ff="SB Sans Display"
        color="#FFFFFF"
        mw={260}
      >
        {color
          ? 'Как был рассчитан размер убытка?'
          : 'Давайте расскажу подробнее о продукте!'}
      </Typography>
      <FlexWrapper>
        <Typography
          m="0 8px 0 0"
          fz={16}
          lh={24}
          fw={400}
          center
          color={lightGreen}
          onClick={() => {
            postEvent({ user, eventId: 7 }).catch(() => navigate('/error'));
            navigate(color ? '/damage-details' : '/about');
          }}
        >
          Узнать больше
        </Typography>
        <RightPointer />
      </FlexWrapper>
    </StyledComponent>
  );
};
