import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Cross, LeftPointer } from '../../assets/icons';
import { useMobile } from '../../hooks';
import { theme } from '../../styles';

type Props = { absolute?: boolean; cross?: boolean };

const StyledComponent = styled.button<Props>`
  padding: 14px 24px 14px 20px;
  background: transparent;
  font-size: 20px;
  line-height: 28px;
  font-family: 'SB Sans Interface';
  font-weight: 400;
  border-radius: 12px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 10px;
  border: 2px solid ${({ theme: t }) => t.colors.background.darkGreen};
  color: #ffffff;
  z-index: 1;
  margin-top: 62px;
  cursor: pointer;
  ${({ cross }) =>
    cross &&
    css`
      svg {
        max-width: 16px;
      }
    `};
  &:hover {
    border: 2px solid ${({ theme: t }) => t.colors.background.darkGreenHover};
  }
  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
      bottom: 36px;
      left: 40px;
    `};
  ${theme.breakpoints.mobile} {
    padding: 11px;
    margin-top: 0;
    height: 42px;
    border: 2px solid ${({ theme: t }) => t.colors.border.mobileBackBtn};
    ${({ absolute }) =>
      absolute &&
      css`
        position: absolute;
        top: 19px;
        left: 16px;
      `};
  }
`;

export const BackBtn = (props: Props) => {
  const navigate = useNavigate();
  const isMobile = useMobile();
  const { cross } = props;
  const btnHandler = () => navigate(-1);

  return (
    <StyledComponent {...props} onClick={btnHandler} className="backBtn">
      {!cross ? <LeftPointer /> : <Cross />}
      {!isMobile && 'Назад'}
    </StyledComponent>
  );
};
