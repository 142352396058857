import { createGlobalStyle } from 'styled-components';
import fonts from './fonts.module.css';

export const GlobalStyles = createGlobalStyle`
${fonts}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SB Sans Text', sans-serif;
  /* font-weight: 600; */
  font-style: normal;
  /* font-size: 24px; */
  /* color: ${(props:any) => props.theme.colors.text.black}; */
}
body {
  min-width: 360px;
  margin: 0 auto;
  background: ${(props:any) => props.theme.colors.background.page};
}
button {
    font-size: 24px;
  background: none;
  border: none;
}
img {
    max-width: 100%;
    object-fit: cover;
}
a {
	text-decoration: none;
}
`;
