import { ReactNode } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { getDamageDataByEvent, getShortenedCost } from '../../services';
import { theme } from '../../styles';
import { Typography } from '../common/Typography';
import { WithBlur } from '../common/WithBlur';

const DamageSources = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  & > div {
    flex-basis: calc(50% - 10px);
    min-width: 240px;
    padding: 27px 16px;
  }
`;

export const getSplittedCost = (cost: number) =>
  new Intl.NumberFormat('ru-RU').format(cost);

export const DamageInfo = ({
  data,
  isMobile,
  children,
}: {
  data: {
    event: string;
    risks: {
      compensationName: string;
      compensationSize: number;
      riskDescription: string;
      riskTitle: string;
    }[];
  } | null;
  isMobile?: boolean;
  children?: ReactNode;
}) => {
  const { asphalt } = theme.colors.text;
  const damageColor = getDamageDataByEvent(data?.event).color;

  return isMobile ? (
    <>
      <Typography fw={500} fz={14} lh={15} color={asphalt} m="0 0 2px">
        Общая сумма убытков
      </Typography>
      <Typography fz={24} lh={30} fw={700} color={damageColor} m="0 0 14px">
        {getShortenedCost(
          data?.risks.reduce((acc, cur) => acc + cur.compensationSize, 0) ?? 0
        )}{' '}
        ₽
      </Typography>
    </>
  ) : (
    <WithBlur
      center
      p="30px min(39px, 5%) 51px"
      mw="min(101.96%, 780px)"
      t="36%"
      mh="548px"
      className="damage-info-blur"
    >
      <Typography fw={500} fz={24} lh={32} color="#ffffff" m="0 0 16px">
        Общая сумма убытков
      </Typography>
      <Typography fz={60} lh={64} fw={700} color={damageColor} m="0 0 37px">
        {getShortenedCost(
          data?.risks.reduce((acc, cur) => acc + cur.compensationSize, 0) ?? 0
        )}{' '}
        ₽
      </Typography>
      <DamageSources>
        {data?.risks.map((risk) => (
          <WithBlur
            key={uuidv4()}
            radius={32}
            borderColor="#ffffff"
            hideFilter
            shadow="0px 0px 4px rgba(0, 0, 0, 0.08), 0px 20px 28px -6px rgba(0, 0, 0, 0.08)"
          >
            <Typography
              fw={500}
              fz={24}
              lh={32}
              center
              color="#ffffff"
              m="auto"
              mw={risk.riskTitle === 'Ущерб имуществу' ? 200 : undefined}
            >
              {risk.riskTitle === 'Сотрудники'
                ? 'Компенсация сотруднику'
                : risk.riskTitle}
            </Typography>
            <Typography fw={500} fz={24} lh={32} center color={damageColor}>
              {getShortenedCost(risk.compensationSize)} ₽
            </Typography>
          </WithBlur>
        ))}
      </DamageSources>
      {children}
    </WithBlur>
  );
};
