import { createContext, Dispatch, SetStateAction } from 'react';

export type InsuranceContextType = {
  insuranceFullCost: number;
  discountSuperpromo: number;
  discountPromo: number;
  discountMaxPercents: number;
  assets: number;
  finance: number;
  responsibility: number;
  staff: number;
  countOfEmployees: number;
  additionalRisks: number;
  insuranceUrl: string;
};

export const InsuranceContext = createContext<{
  insuranceContext: InsuranceContextType;
  setInsuranceContext: Dispatch<SetStateAction<InsuranceContextType>>;
}>({
  insuranceContext: {
    insuranceFullCost: 0,
    discountSuperpromo: 0,
    discountPromo: 0,
    discountMaxPercents: 27,
    assets: 0,
    finance: 0,
    responsibility: 0,
    staff: 0,
    countOfEmployees: 0,
    additionalRisks: 0,
    insuranceUrl: '',
  },
  setInsuranceContext: () => null,
});
