import { MemoryRouter, Route, Routes } from 'react-router-dom';
import { AccidentPage } from '../pages/AccidentPage';
import { BusinessSelectPage } from '../pages/BusinessSelectPage';
import { QuizPage } from '../pages/QuizPage';
import { FacilitySelectPage } from '../pages/FacilitySelectPage';
import { StoryPage } from '../pages/StoryPage';
import { DamagePage } from '../pages/DamagePage';
import { InsurancePage } from '../pages/InsurancePage';
import { MainPage } from '../pages/MainPage';
import { RequestCallPage } from '../pages/RequestCallPage';
import { ErrorPage } from '../pages/ErrorPage';
import { RequestCallSuccessPage } from '../pages/RequestCallSuccessPage';
import { AboutPage } from '../pages/AboutPage';
import { DamageDetails } from '../pages/DamageDetails';

export const Router = () => (
  <MemoryRouter>
    <Routes>
      <Route path="*" element={<MainPage />} />
      <Route path="select-business" element={<BusinessSelectPage />} />
      <Route path="select-facility" element={<FacilitySelectPage />} />
      <Route path="quiz/:id" element={<QuizPage />} />
      <Route path="accident" element={<AccidentPage />} />
      <Route path="damage/:id" element={<DamagePage />} />
      <Route path="damage-details" element={<DamageDetails />} />
      <Route path="insurance" element={<InsurancePage />} />
      <Route path="story/:id" element={<StoryPage />} />
      <Route path="request-call" element={<RequestCallPage />} />
      <Route path="request-call/success" element={<RequestCallSuccessPage />} />
      <Route path="error" element={<ErrorPage />} />
      <Route path="about" element={<AboutPage />} />
    </Routes>
  </MemoryRouter>
);
