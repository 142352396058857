import React from 'react';
import { useNavigate } from 'react-router-dom';

import { theme } from '../styles';
import { useMobile } from '../hooks';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StandMobile } from '../components/common/StandMobile';
import { Typography } from '../components/common/Typography';
import { Button } from '../components/buttons/Button';
import { BackBtn } from '../components/buttons/BackBtn';

const {
  text: { lightGreen },
  background: { darkGreen },
} = theme.colors;

export const RequestCallSuccessPage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();

  const handleClick = () => navigate('/insurance');

  return (
    <PageWrapper
      p={isMobile ? '120px 8px 8px' : '36px 40px'}
      column
      gap="0px"
      isMobile={isMobile}
      hideDiscount
      backgroundColor="green"
    >
      {isMobile ? (
        <>
          <StandMobile onlyGradient backgroundColor="green" />
          <Typography
            mw={328}
            m="0 0 264px 0"
            fz={24}
            lh={28}
            fw={700}
            center
            color="#FFFFFF"
          >
            Заявка принята
            <br /> в работу, менеджер свяжется с вами в&nbsp;ближайшее время
          </Typography>
        </>
      ) : (
        <Typography
          mw={682}
          m="0 0 226px 0"
          fz={40}
          lh={48}
          fw={700}
          ls="-0.02em"
          center
          color="#FFFFFF"
        >
          Заявка принята в работу, менеджер свяжется с вами в ближайшее время
        </Typography>
      )}
      <Button
        withGradientBg
        mw={isMobile ? '344px' : '260px'}
        p={isMobile ? '19px 32px' : '30px 14px'}
        radius={isMobile ? 16 : 24}
        fz={isMobile ? 16 : 20}
        lh={isMobile ? 20 : 30}
        fw={500}
        ls="-0.02em"
        color={lightGreen}
        borderColor={darkGreen}
        onClick={handleClick}
      >
        Понятно
      </Button>
      {!isMobile && <BackBtn absolute />}
    </PageWrapper>
  );
};
