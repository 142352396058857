import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  children: ReactNode;
  className?: string;
  width?:number;
  fz?: number;
  m?: string;
  p?: string;
  fw?: number;
  lh?: string | number;
  ls?: string;
  ff?: string;
  mw?: number;
  color?: string;
  center?: boolean;
  border?: boolean;
  crossed?: boolean;
  underline?: boolean;
  onClick?: () => void;
};

const StyledComponent = styled.div<Props>`
  z-index: 1;
  ${({ ff }) =>
    ff &&
    css`
      font-family: ${ff};
    `};
  ${({ m }) =>
    m &&
    css`
      margin: ${m};
    `};
  ${({ p }) =>
    p &&
    css`
      padding: ${p};
    `};
  ${({ fz }) =>
    fz &&
    css`
      font-size: ${fz}px;
    `};
  ${({ fw }) =>
    fw &&
    css`
      font-weight: ${fw};
    `};
  ${({ lh }) =>
    lh &&
    css`
      line-height: ${() => {
        if (String(lh).includes('%')) return lh;
        return `${lh}px;`;
      }};
    `};
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
  ${({ ls }) =>
    ls &&
    css`
      letter-spacing: ${ls};
    `};
  ${({ mw }) =>
    mw &&
    css`
      max-width: ${mw}px;
    `};
  ${({ width }) =>
      width &&
      css`
      width: ${width}px;
    `};
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `};
  ${({ crossed }) =>
    crossed &&
    css`
      text-decoration: line-through;
    `};
  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `};
`;

export const Typography = (props: Props) => <StyledComponent {...props} />;
