import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../styles';

type Props = {
  children: ReactNode;
  onClick?: () => void;
  p?: string;
  fw?: number;
  ff?: string;
  fz?: number;
  lh?: number;
  ls?: string;
  m?: string;
  mw?: string;
  h?: number;
  color?: string;
  bg?: string;
  radius?: number;
  borderColor?: string;
  withGradientBg?: boolean;
  disabled?: boolean;
  withHover?: boolean;
};

export const StyledComponent = styled.button<Props>`
  z-index: 1;
  cursor: pointer;
  position: relative;
  font-family: 'SB Sans Text';
  padding: 10px;
  color: ${({ theme: t, color }) => color ?? t.colors.text.lightGreen};
  background-color: transparent;
  width: 100%;
  ${({ mw }) =>
    mw &&
    css`
      width: ${mw} !important;
    `};
  ${({ h }) =>
    h &&
    css`
      height: ${h}px;
    `};
  ${({ p }) =>
    p &&
    css`
      padding: ${p} !important;
    `};
  ${({ radius }) =>
    radius &&
    css`
      border-radius: ${radius}px !important;
    `};
  ${({ m }) =>
    m &&
    css`
      margin: ${m};
    `};
  ${({ withGradientBg }) =>
    withGradientBg &&
    css`
      background: ${({ theme: { colors } }) => colors.gradient.button};
      &:hover {
        background: ${({ theme: { colors } }) => colors.gradient.buttonHover};
      }
    `};
  ${({ withGradientBg }) =>
    !withGradientBg &&
    css`
      &:hover {
        border: 2px solid
          ${({ theme: { colors } }) => colors.background.darkGreenHover};
      }
    `};
  ${({ fz }) =>
    fz &&
    css`
      font-size: ${fz}px !important;
    `};
  ${({ lh }) =>
    lh &&
    css`
      line-height: ${lh}px !important;
    `};
  ${({ fw }) =>
    fw &&
    css`
      font-weight: ${fw};
    `};
  ${({ ls }) =>
    ls &&
    css`
      letter-spacing: ${ls};
    `};
  ${({ ff }) =>
    ff &&
    css`
      font-family: ${ff};
    `};
  ${({ borderColor }) =>
    borderColor &&
    css`
      border: 2px solid ${borderColor};
    `};
  ${({ withHover, theme: t }) =>
    withHover &&
    css`
      &:hover {
        border: 2px solid ${t.colors.background.darkGreenHover};
      }
    `};
  ${theme.breakpoints.mobile} {
    width: 344px;
    padding: 17px 32px;
    border-radius: 16px;
    line-height: 20px;
    font-size: 16px;
    height: 100%;
  }
`;

export const Button = (props: Props) => <StyledComponent {...props} />;
