import cupImg from '../assets/images/cup.png';
import characterOneGif from '../assets/images/character/character-one.gif';
import characterTwoGif from '../assets/images/character/character-two.gif';
import characterThreeGif from '../assets/images/character/character-three.gif';
import characterFourGif from '../assets/images/character/character-four.gif';
import storyCafeImg from '../assets/images/story/story-cafe.png';
import characterFiveGif from '../assets/images/character/character-five.gif';

export const getSpotImageData = (props?: {
  randomBoolean?: boolean;
  img?: string;
}) => [
  {
    img: characterFiveGif,
    w: '80.12%',
    h: '114.34%',
    b: '-8.5%',
  },
  {
    img: props?.randomBoolean ? characterOneGif : characterFourGif,
    w: '78.12%',
    h: '111.34%',
    b: '-5.5%',
  },
  {
    img: characterThreeGif,
    w: '78.12%',
    h: '111.34%',
    b: '-5.5%',
  },
  {
    img: characterTwoGif,
    w: '78.12%',
    h: '111.34%',
    b: '-5.5%',
  },
  {
    img: cupImg,
    w: '115.03%',
    h: '115.03%',
    left: 61,
  },
  {
    img: props?.img ?? storyCafeImg,
    w: '115.03%',
    h: '115.03%',
    left: 55.23,
  },
];
