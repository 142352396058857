export const File = () => (
  <svg
    width="24"
    height="30"
    viewBox="0 0 24 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6654 2.02539V7.53278C14.6654 8.27951 14.6654 8.65288 14.8107 8.9381C14.9385 9.18898 15.1425 9.39295 15.3934 9.52079C15.6786 9.66611 16.052 9.66611 16.7987 9.66611H22.3061M17.332 16.3327H6.66536M17.332 21.666H6.66536M9.33203 10.9993H6.66536M14.6654 1.66602H7.73203C5.49182 1.66602 4.37172 1.66602 3.51607 2.10199C2.76342 2.48548 2.1515 3.09741 1.76801 3.85005C1.33203 4.7057 1.33203 5.82581 1.33203 8.06602V21.9327C1.33203 24.1729 1.33203 25.293 1.76801 26.1486C2.1515 26.9013 2.76342 27.5132 3.51607 27.8967C4.37172 28.3327 5.49182 28.3327 7.73203 28.3327H16.2654C18.5056 28.3327 19.6257 28.3327 20.4813 27.8967C21.234 27.5132 21.8459 26.9013 22.2294 26.1486C22.6654 25.293 22.6654 24.1729 22.6654 21.9327V9.66602L14.6654 1.66602Z"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
