import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { v4 as uuidv4 } from 'uuid';

import { theme } from '../styles';

import {
  calculateRisks,
  getDamageDataByEvent,
  getSpotImageData,
  postEvent,
} from '../services';
import { useMobile } from '../hooks';
import { getUserId } from '../services/userService';
import { RisksHintCard } from '../components/cards/RisksHintCard';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StandMobile } from '../components/common/StandMobile';
import { Typography } from '../components/common/Typography';
import { Stand } from '../components/common/Stand';
import { Column } from '../components/common/Column';
import { DamageInfo } from '../components/damage/DamageInfo';
import { Button } from '../components/buttons/Button';
import { FooterButtonsWrapper } from '../components/buttons/FooterButtonsWrapper';
import { Bubble } from '../components/insurance/Bubble';
import { QuizContext } from '../context/Quiz';
import { InsuranceContext } from '../context/Promo';


const Hints = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  padding-bottom: 50px;
  min-height: 614px;
  align-items: flex-start;
  & > div {
    overflow: visible !important;
  }
  .react-swipeable-view-container {
    max-width: 79.44vw;
    & > div {
      &:not(:last-child) {
        padding-right: 8px;
      }
    }
  }
  ${theme.breakpoints.mobile} {
    min-height: unset;
    margin-bottom: auto;
    row-gap: 20px;
    column-gap: 16px;
    a {
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      font-family: 'SB Sans Interface';
      color: #21a19a;
      cursor: pointer;
      padding-bottom: 2px;
      border-bottom: 1px solid #21a19a;
    }
  }
`;

const Disclaimer = styled.div`
  position: absolute;
  bottom: 18px;
  left: 50%;
  font-family: 'SB Sans Interface';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.35);
  transform: translate(-50%);
  ${theme.breakpoints.mobile} {
    font-size: 10px;
    line-height: 10px;
    width: 100%;
    max-width: min(330px, 100%);
    bottom: 10px;
    text-align: center;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 14px;
  margin-bottom: 62px;
  max-width: 90%;
  flex-wrap: wrap;
  justify-content: center;
`;

const desktopButtonStyles = {
  radius: 24,
  p: '30px 16px',
  fz: 20,
  lh: 30,
  mw: '260px',
  ls: '-0.02e',
};

const mobileButtonStyles = {
  radius: 16,
  p: '19px',
  fz: 16,
  lh: 20,
  mw: undefined,
  ls: undefined,
};

type DamageData = {
  event: string;
  risks: {
    compensationName: string;
    compensationSize: number;
    riskDescription: string;
    riskTitle: string;
  }[];
};

const SwipeableComp = ({
  damageData,
  isMobile,
}: {
  damageData: DamageData | null;
  isMobile: boolean;
}) =>
  damageData ? (
    <SwipeableViews enableMouseEvents>
      {damageData.risks.map((hint) => (
        <RisksHintCard
          {...hint}
          key={uuidv4()}
          h="100%"
          isMobile={isMobile}
          color={getDamageDataByEvent(damageData?.event).color}
        />
      ))}
    </SwipeableViews>
  ) : (
    <div />
  );

export const DamagePage = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();
  const id = Number(useParams().id);
const user = getUserId();
  const { quizContext } = useContext(QuizContext);
  const {
    insuranceContext: {
      discountMaxPercents,
      insuranceUrl,
      discountPromo,
      discountSuperpromo
    },
  } = useContext(InsuranceContext);

  const { malachite } = theme.colors.border;

  const [damageData, setDamageData] = useState<DamageData | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      return () => {};
    }
    const handler = () => {
      const stand = document.querySelector('.stand-wrapper');
      const damageInfoBlur = document.querySelector('.damage-info-blur');
      const backBtn = document.querySelector('.backBtn') as HTMLElement;

      if (!stand || !damageInfoBlur || !backBtn) return;

      const standTop = stand.getBoundingClientRect().y;
      const standHeight = stand.getBoundingClientRect().height;
      const blurBlockTop = 0.36 * standHeight;
      const blurBlockHeight = damageInfoBlur.getBoundingClientRect().height;
      const blurBlockBottomY = standTop + blurBlockTop + blurBlockHeight;
      const backBtnY = backBtn.getBoundingClientRect().y;
      const diff = backBtnY - blurBlockBottomY;

      if (diff < 28) {
        const newMarginTop = `${62 + (28 - diff)}px`;
        backBtn.style.marginTop = newMarginTop;
      }
    };
    handler();
    if (!isMobile) {
      window.addEventListener('resize', handler);
    } else {
      window.removeEventListener('resize', handler);
    }
    return () => window.removeEventListener('resize', handler);
  }, [mounted, isMobile]);

  useEffect(() => {
    setDamageData(null);
    calculateRisks({
      ...quizContext,
      calculationStep: id,
      user,
    })
      .then(({ data }) => setDamageData(data))
      .catch(() => navigate('/error'));
  }, [id, quizContext, user]);

  const getPromocode = (): string =>
    discountMaxPercents === 0.27 ? 'GAMIFICATION40' : 'SUPERPROMO';
  const getPromocodeUrl = () => {
    const expectedPrice = 
      (discountMaxPercents === 0.27 ? discountPromo : discountSuperpromo).toFixed(2);
    return `${insuranceUrl}&expectedPrice=${expectedPrice}&promoCode=${getPromocode()}`;
  };

  return (
    <PageWrapper
      p={isMobile ? '0 8px 85px' : '0 40px 36px'}
      isMobile={isMobile}
      column={isMobile}
      hideDiscount
      backgroundColor={getDamageDataByEvent(damageData?.event).backgroundColor}
    >
      {isMobile ? (
        <>
          <StandMobile
            onlyGradient
            backgroundColor={
              getDamageDataByEvent(damageData?.event).backgroundColor
            }
          />
          <Typography
            lh={28}
            fz={24}
            center
            color="#ffffff"
            m="70px 0 14px 0"
            mw={300}
            fw={700}
          >
            {getDamageDataByEvent(damageData?.event).title}
          </Typography>
          <DamageInfo data={damageData} isMobile={isMobile} />
          <Hints>
            <SwipeableComp isMobile={isMobile} damageData={damageData} />
            {id === 1 && (
              <Link to="/damage-details">
                Подробнее о расчете размера убытка
              </Link>
            )}
          </Hints>
          <Button
            onClick={() => {
              if (id === 1) navigate('/insurance');
              else {
                postEvent({ user, eventId: 9 }).catch(() => navigate('/error'));
                const link = document.createElement('a');
                link.href = getPromocodeUrl();
                document.body.appendChild(link);
                link.click();
              }
            }}
            {...mobileButtonStyles}
            fw={500}
            m="0 0 4px"
            withGradientBg
            borderColor={malachite}
          >
            {id === 1
              ? 'Если бы была страховка'
              : 'Забрать скидку и застраховаться'}
          </Button>
          {id > 1 && (
            <Button
              onClick={() => navigate('/request-call')}
              {...mobileButtonStyles}
              borderColor={malachite}
            >
              Заказать звонок консультанта
            </Button>
          )}
          {![1].includes(id) && <FooterButtonsWrapper id={id} isMobile />}
        </>
      ) : (
        <>
          <Stand
            person={getSpotImageData()[2]}
            circleImage={getDamageDataByEvent(damageData?.event).circleImage}
            withBackBtn
          >
            <DamageInfo data={damageData} />
            {id === 1 && (
              <Bubble
                color={getDamageDataByEvent(damageData?.event).backgroundColor}
              />
            )}
          </Stand>
          <Column p="4.16vw 0 0">
            <div>
              <Typography
                lh={64}
                fz={60}
                fw={700}
                center
                color="#ffffff"
                m="0 0 40px 0"
              >
                {getDamageDataByEvent(damageData?.event).title}
              </Typography>
              <Hints>
                {damageData?.risks.map((hint) => (
                  <RisksHintCard {...hint} key={uuidv4()} w={366} h="274px" />
                ))}
              </Hints>
            </div>
            <ButtonsWrapper>
              <Button
                onClick={() => {
                  if (id === 1) navigate('/insurance');
                  else {
                    postEvent({ user, eventId: 9 }).catch(() =>
                      navigate('/error')
                    );
                    const link = document.createElement('a');
                    link.href = getPromocodeUrl();
                    document.body.appendChild(link);
                    link.click();
                  }
                }}
                {...desktopButtonStyles}
                fw={500}
                withGradientBg
                borderColor={malachite}
              >
                {id === 1
                  ? 'Если бы была страховка'
                  : 'Забрать скидку и застраховаться'}
              </Button>
              {id > 1 && (
                <Button
                  onClick={() => navigate('/request-call')}
                  {...desktopButtonStyles}
                  fw={500}
                  borderColor={malachite}
                >
                  Заказать звонок консультанта
                </Button>
              )}
            </ButtonsWrapper>
            {![1].includes(id) && <FooterButtonsWrapper id={id} />}
          </Column>
        </>
      )}
      <Disclaimer>
        Информация приведена в целях иллюстрации возможных событий на объекте
        недвижимости
      </Disclaimer>
    </PageWrapper>
  );
};
