import {
  RefObject,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { BackBtn } from '../buttons/BackBtn';
import { Typography } from '../common/Typography';
import { theme } from '../../styles';

import { ProgressIndicator } from '../quiz/ProgressIndicator';
import pigImage from '../../assets/images/pig/pig_42.png';
import { IdsHistoryContext } from '../../context/IdsHistory';
import { InsuranceContext } from '../../context/Promo';

const StyledComponent = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2;
  min-width: 360px;
  display: none;
  padding: 16px 16px 3px;
  height: 60px;
  ${theme.breakpoints.mobile} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const DiscountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PigHint = styled.div<{ showPigHint: boolean }>`
  position: absolute;
  background-color: #050916;
  top: 16px;
  border-radius: 24px;
  max-width: 400px;
  padding: 0;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  width: 97.77%;
  right: 0;
  padding: 16px 70px 16px 14px;
  color: #ffffff;
  font-family: 'SB Sans Interface';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  ${({ showPigHint }) =>
    showPigHint &&
    css`
      z-index: 1;
      opacity: 1;
    `}
`;

const PigImg = styled.div<{
  showPigHint: boolean;
  img?: string;
  index?: number;
}>`
  transition: margin 0.5s ease-in-out;
  z-index: 2;
  width: 42px;
  height: 69px;
  background-size: cover;
  ${({ img }) => img && `background: url(${img})`};
  background-position: ${({ index = 1 }) => `${(80 - index) * 42}px 0`};
  margin-top: ${({ showPigHint }) => (showPigHint ? '-15px' : '-22px')};
`;

export const MobileHeader = ({
  hideBackBtn,
  hideDiscount,
  indicatorPercents,
  pigIndexDefault,
  pigHintRef,
}: {
  hideBackBtn?: boolean;
  hideDiscount?: boolean;
  indicatorPercents?: number;
  pigIndexDefault?: null | number;
  pigHintRef?: RefObject<{
    triggerPigHint: () => void;
  }>;
}) => {
  const location = useLocation();

  const { idsHistoryContext } = useContext(IdsHistoryContext);
  const { insuranceContext } = useContext(InsuranceContext);

  const percents = (1 + idsHistoryContext.length) / 0.11;

  const [showPigHint, setShowPigHint] = useState(false);

  const [pigIndex, setPigIndex] = useState(pigIndexDefault ?? 0);
  // const { files } = useContext(PrefetchFilesContext);
  useEffect(() => {
    if (pigIndexDefault) setPigIndex((prev) => 1);
  }, [pigIndexDefault]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (pigIndex === 80) clearInterval(interval);
      else if (pigIndex !== 0)
        setPigIndex((prev) => (prev === 79 ? 0 : prev + 1));
    }, 30);
    return () => clearInterval(interval);
  }, [pigIndex]);

  useImperativeHandle(pigHintRef, () => ({
    triggerPigHint() {
      setShowPigHint(true);
    },
  }));

  const getNotQuizPercents = () => {
    if (location.pathname.includes('select-business')) return 0;
    if (location.pathname.includes('select-facility'))
      return Math.round(insuranceContext.discountMaxPercents / 0.11);
    return insuranceContext.discountMaxPercents * 100;
  };

  return (
    <StyledComponent>
      {!hideBackBtn && <BackBtn />}
      {indicatorPercents !== undefined && (
        <ProgressIndicator percents={indicatorPercents} />
      )}
      {!hideDiscount && (
        <DiscountWrapper>
          <Typography m="0 6px 0 0" fz={20} lh={26} color="#FFFFFF">
            {!location.pathname.includes('quiz')
              ? getNotQuizPercents()
              : Math.round(percents * insuranceContext.discountMaxPercents)}
            %
          </Typography>
          <PigImg
            img={pigImage}
            onClick={() => setShowPigHint((prev) => !prev)}
            onKeyDown={() => setShowPigHint((prev) => !prev)}
            showPigHint={showPigHint}
            index={pigIndex}
          />
        </DiscountWrapper>
      )}
      <PigHint showPigHint={showPigHint} onClick={() => setShowPigHint(false)}>
        Твоя скидка на кое-что очень важное...
      </PigHint>
    </StyledComponent>
  );
};
