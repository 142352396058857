import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { QuizContext } from '../context/Quiz';
import { useMobile } from '../hooks';
import { CalculateDto } from '../models';
import { getBusinessCards, getSpotImageData, postEvent } from '../services';
import spritesheet1 from '../assets/images/character/spritesheet-character-one.png';
import spritesheet2 from '../assets/images/character/spritesheet-character-four.png';
import { getUserId } from '../services/userService';
import { PageTitle } from '../components/layout/PageTitle';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StandMobile } from '../components/common/StandMobile';
import { Stand } from '../components/common/Stand';
import { Column } from '../components/common/Column';
import { ChoiceCard } from '../components/cards/ChoiceCard';
import { ChoiceCardsContainer } from '../components/cards/ChoiceCardsContainer';
import { BlurredDiscountBlock } from '../components/quiz/BlurredDiscountBlock';
import { ProgressIndicator } from '../components/quiz/ProgressIndicator';


export const BusinessSelectPage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();
  const { setQuizContext } = useContext(QuizContext);

  const user = getUserId();

  const randomBoolean = useMemo(() => Math.random() < 0.5, []);

  return (
    <PageWrapper
      p={isMobile ? '0 19px 36px' : '0 40px 36px'}
      isMobile={isMobile}
      column={isMobile}
      indicatorPercents={0}
      backgroundColor="green"
    >
      {isMobile ? (
        <>
          <StandMobile
            img={randomBoolean ? spritesheet1 : spritesheet2}
            backgroundColor="green"
          />
          <PageTitle m="-8px 0 18px" fz={24} lh={28} mw={322} fw={700}>
            Выберите свой бизнес
          </PageTitle>
          <ChoiceCardsContainer>
            {getBusinessCards({ isMobile: true }).map((c) => (
              <ChoiceCard
                key={c.value}
                title={c.title}
                image={c.image}
                isMobile
                w={74}
                onClick={() => {
                  setQuizContext(
                    (prev) =>
                      ({ ...prev, businessType: c.value } as CalculateDto)
                  );
                  postEvent({ user, eventId: 2 }).catch(() =>
                    navigate('/error')
                  );
                  navigate('/select-facility');
                }}
              />
            ))}
          </ChoiceCardsContainer>
        </>
      ) : (
        <>
          <Stand person={getSpotImageData({ randomBoolean })[1]} withBackBtn>
            <BlurredDiscountBlock percents={0} />
          </Stand>
          <Column mw={694} p="min(89px,4.63vw) 0 0">
            <ProgressIndicator percents={0} />
            <PageTitle m="20px 0 40px 0" fw={700}>
              Выберите свой бизнес
            </PageTitle>
            <ChoiceCardsContainer>
              {getBusinessCards({ isMobile: false }).map((c) => (
                <ChoiceCard
                  key={c.value}
                  title={c.title}
                  image={c.image}
                  onClick={() => {
                    setQuizContext(
                      (prev) =>
                        ({ ...prev, businessType: c.value } as CalculateDto)
                    );
                    postEvent({ user, eventId: 2 }).catch(() =>
                      navigate('/error')
                    );
                    navigate('/select-facility');
                  }}
                />
              ))}
            </ChoiceCardsContainer>
          </Column>
        </>
      )}
    </PageWrapper>
  );
};
